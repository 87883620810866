











































































  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'ImapTokensList',
    components: {
      Icon,
      Modal,
      ModalConfirmActions,
      PasswordField,
    },
    mixins: [asyncActionsMixin],
    props: {
      imapTokens: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        password: '',
        validate: false,
        authenticationError: '',
      };
    },
    computed: {
      ...mapGetters('layout', ['densityOption']),
      deleteText(): string {
        return this.$gettext('Remove');
      },
      passwordText(): string {
        return this.$gettext('Confirm with your account password');
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('imapTokens', ['deleteImapToken']),
      removeImapToken: asyncAction(
        'removing',
        function (
          this: any,
          identifier: string,
          password: string,
          toggle: Function
        ) {
          this.validate = true;
          if (!password) return;
          return this.deleteImapToken({ identifier, password })
            .then(() => {
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$gettext(
                    'Trusted device %{ identifier } has been deleted'
                  ),
                  { identifier }
                ),
              });
              toggle();
            })
            .catch((error: AxiosError) => {
              const loginErrorCode = getErrorCode(error);
              if (loginErrorCode === AUTHENTICATION_FAILURE) {
                this.authenticationError = this.$gettext('Wrong password.');
                this.$nextTick(() => {
                  this.$refs.password[0].focus();
                });
              } else {
                toggle();
                this.setToastMessage({
                  message: this.$gettext(
                    'Sorry, we could not delete your device. Please try again later'
                  ),
                });
                throw error;
              }
            });
        }
      ),
      resetState() {
        this.password = '';
        this.validate = false;
        this.authenticationError = '';
      },
    },
  });
