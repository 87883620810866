import Vue from 'vue';

export default Vue.extend({
  methods: {
    getContactGroupName({ id, name }: { id: string; name: string }) {
      switch (id) {
        case 'all':
          return this.$gettext('All');
        case 'favorites':
          return this.$gettext('Favorites');
        default:
          return name;
      }
    },
    iconForGroup(groupId: string): string {
      switch (groupId) {
        case 'all':
          return 'group';
        case 'favorites':
          return 'favorites';
        default:
          return 'contact-group';
      }
    },
  },
});
