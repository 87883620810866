




















































  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import GroupSubscriptionAccount from '@/components/GroupSubscription/GroupSubscriptionAccount.vue';
  import GroupSubscriptionCreateAccountModal from '@/components/GroupSubscription/GroupSubscriptionCreateAccountModal.vue';
  import GroupSubscriptionCreateAccountNotifications from '@/components/GroupSubscription/GroupSubscriptionCreateAccountNotifications.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import GroupSubscriptionAccountBanner from '@/components/GroupSubscription/GroupSubscriptionAccountBanner.vue';
  import GroupSubscriptionWarning from '@/components/GroupSubscription/GroupSubscriptionWarning.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionAccounts',
    components: {
      GroupSubscriptionAccount,
      GroupSubscriptionCreateAccountModal,
      Icon,
      GroupSubscriptionAccountBanner,
      GroupSubscriptionCreateAccountNotifications,
      GroupSubscriptionWarning,
    },
    data() {
      return {
        createGroupSubscriptionNotifications: {},
      };
    },
    computed: {
      ...mapGetters('subscription', {
        getAdditionalAccounts: 'getAdditionalAccounts',
        getManagerAccounts: 'getManagerAccounts',
        isAccountCreateAllowed: 'isAccountCreateAllowed',
      }),
      ownerAccountTitle(): string {
        return this.$gettext('Subscription manager');
      },
      ownerAccountSubAccount(): string {
        return this.$gettext('Additional accounts');
      },
      getTitleText(): string {
        return this.$gettext('Group subscription');
      },
    },
  });
