<template>
  <div>
    <section class="panel">
      <h2 class="panel__title" v-test:notificationAndWarnings>
        <translate>Notifications and warnings</translate>
      </h2>
      <div class="panel__section">
        <FlipSwitch
          class="panel__section-item"
          id="recipientCountWarning"
          :label="recipientCountWarningLabel"
          v-model="recipientCountWarning"
          v-test:recipientCountWarning
        />
        <FlipSwitch
          class="panel__section-item"
          id="externalLinkWarning"
          :label="externalLinkWarningLabel"
          v-model="externalLinkWarning"
          v-test:externalLinkWarning
        />
      </div>
    </section>

    <feature-toggle :id="FEATURE_AUTO_REPLY">
      <section class="panel">
        <h2 class="panel__title">
          <translate>Auto-reply</translate>
        </h2>
        <div class="panel__section">
          <AutoReplySettings />
        </div>
      </section>
    </feature-toggle>

    <section class="panel">
      <h2 class="panel__title" v-test:composeMessage>
        <translate>Compose message</translate>
      </h2>
      <div class="panel__section">
        <div class="panel__section-item">
          <h3 class="panel__section-title" v-test:signature>
            <translate>Signature</translate>
          </h3>
          <SignatureManagement />
        </div>
        <div class="panel__section-item">
          <h3 class="panel__section-title" v-test:defaultTextStyling>
            <translate>Default text styling</translate>
          </h3>
          <DefaultTextSettings v-test:defaultTextSettings />
        </div>
      </div>
    </section>

    <RecipientListSettings v-test:recipientListSettings />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import AutoReplySettings from '@/components/AutoReplySettings.vue';
  import DefaultTextSettings from '@/components/DefaultTextSettings/DefaultTextSettings';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';
  import RecipientListSettings from '@/components/RecipientListSettings/RecipientListSettings.vue';
  import SignatureManagement from '@/components/SignatureManagement/SignatureManagement';
  import { FEATURE_AUTO_REPLY } from '@/lib/featureFlags';

  export default {
    components: {
      AutoReplySettings,
      DefaultTextSettings,
      FlipSwitch,
      RecipientListSettings,
      SignatureManagement,
    },
    data() {
      return {
        FEATURE_AUTO_REPLY,
      };
    },
    computed: {
      ...mapState('authentication', ['user']),
      externalLinkWarning: {
        get() {
          return this.user.preferences.external_link_warning === true;
        },
        set(externalLinkWarning) {
          this.setExternalLinkWarning({ externalLinkWarning });
        },
      },
      recipientCountWarning: {
        get() {
          return this.user.preferences.recipient_count_warning === true;
        },
        set(recipientCountWarning) {
          this.setRecipientCountWarning({ recipientCountWarning });
        },
      },
      externalLinkWarningLabel() {
        return this.$gettext('Always warn me when clicking an external link');
      },
      recipientCountWarningLabel() {
        return this.$gettext(
          'Always show a notification to move recipients to Bcc when there are more than 25 recipients'
        );
      },
    },
    methods: {
      ...mapActions(['setExternalLinkWarning', 'setRecipientCountWarning']),
    },
  };
</script>
