



















  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import GroupSubscriptionAccountPricing from '@/components/GroupSubscription/GroupSubscriptionAccountPricing.vue';
  import GroupSubscriptionAccounts from '@/components/GroupSubscription/GroupSubscriptionAccounts.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import SkeletonList from '@/components/SkeletonList/SkeletonList.vue';
  import api from '@/api';

  export default Vue.extend({
    name: 'GroupSubscriptionPage',
    components: {
      GroupSubscriptionAccountPricing,
      GroupSubscriptionAccounts,
      NotificationBlock,
      SkeletonList,
    },
    data() {
      return {
        isLoading: true,
        isLoadingFailed: false,
      };
    },
    async created() {
      try {
        await Promise.all([
          this.getGroupSubscriptions(),
          this.getUserCurrency(),
          this.getChargebeePlan(),
        ]);
      } catch {
        this.isLoadingFailed = true;
      } finally {
        this.isLoading = false;
      }

      api.uiEvents.create({
        event_type: 'settings_group_subscription_settings_opened',
      });
    },
    computed: {
      ...mapGetters('subscription', ['hasMultipleAccounts']),
      errorMessageText(): string {
        return this.$gettext('Something went wrong. Please try again later.');
      },
    },
    methods: {
      ...mapActions('subscription', [
        'getChargebeePlan',
        'getGroupSubscriptions',
        'getUserCurrency',
      ]),
    },
  });
