var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultDialog',{attrs:{"narrow":"","closable":!_vm.loading},scopedSlots:_vm._u([{key:"showModal",fn:function(ref){
var showModal = ref.showModal;
return [_c('button',{staticClass:"button",class:{
        'button--primary': !_vm.card.current && _vm.card.available,
        'button--accent': _vm.card.current || !_vm.card.available,
      },attrs:{"disabled":_vm.card.current || !_vm.card.available},domProps:{"textContent":_vm._s(_vm.buttonText)},on:{"click":function($event){_vm.loadEstimate();
        showModal();}}})]}},{key:"header",fn:function(){return [_c('translate',[_vm._v("Order summary")])]},proxy:true},{key:"default",fn:function(ref){
        var close = ref.close;
return [_c('form',{attrs:{"disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.switchPlan($event, close)}}},[_c('div',{staticClass:"contents"},[_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" You are about to switch plans. You will only be charged for the difference in price between your current and your new plan, and only for the remainder of your current term. ")]),_c('dl',[_c('dt',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"new-plan"},[_vm._v("New plan")]),(_vm.estimate)?_c('dd',[(_vm.card.plan.type === 'personal')?[_c('translate',{attrs:{"translate-context":"plan"}},[_vm._v("Personal")])]:(_vm.card.plan.type === 'business')?[_c('translate',{attrs:{"translate-context":"plan"}},[_vm._v("Business")])]:_vm._e(),_vm._v(" "+_vm._s(' ')+" "),(_vm.card.variant.period === 'yearly')?[_c('translate',{attrs:{"translate-context":"billing period"}},[_vm._v("(yearly)")])]:(_vm.card.variant.period === 'monthly')?[_c('translate',{attrs:{"translate-context":"billing period"}},[_vm._v("(monthly)")])]:_vm._e()],2):_vm._e(),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Price (prorated)")]),(_vm.estimate)?_c('dd',{domProps:{"textContent":_vm._s(
              _vm.formatCurrency(_vm.estimate.sub_total, _vm.estimate.currency_code)
            )}}):_vm._e(),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Discount")]),(_vm.estimate)?_c('dd',{domProps:{"textContent":_vm._s(_vm.formatCurrency(_vm.estimate.discount, _vm.estimate.currency_code))}}):_vm._e(),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"total"},[_vm._v("Total")]),(_vm.estimate)?_c('dd',{staticClass:"total",domProps:{"textContent":_vm._s(_vm.formatCurrency(_vm.estimate.total, _vm.estimate.currency_code))}}):_vm._e(),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Credits applied")]),(_vm.estimate)?_c('dd',{domProps:{"textContent":_vm._s(
              _vm.formatCurrency(_vm.estimate.credits_applied, _vm.estimate.currency_code)
            )}}):_vm._e(),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"amount-due"},[_vm._v("Amount due")]),(_vm.estimate)?_c('dd',{staticClass:"amount-due",domProps:{"textContent":_vm._s(
              _vm.formatCurrency(_vm.estimate.amount_due, _vm.estimate.currency_code)
            )}}):_vm._e()]),(_vm.legacy)?_c('NotificationBlock',{attrs:{"variant":"info","has-icon":true}},[_c('translate',[_vm._v(" After switching to a new plan, you will not be able to switch back to your legacy plan. ")])],1):_vm._e(),_c('PasswordField',{staticClass:"form-field",attrs:{"id":("password-" + (_vm.card.plan.type)),"autocomplete":"off","required":"","label-text":_vm.passwordLabelText,"show-custom-validity":_vm.wrongPassword,"validate-input":_vm.wrongPassword,"validation-error-message":_vm.wrongPasswordText},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"button",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return close.apply(null, arguments)}}},[_c('translate',[_vm._v("Cancel")])],1),_c('LoadingButton',{staticClass:"button button--primary",attrs:{"type":"submit","disabled":!_vm.estimate,"loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.confirmButtonText)+" ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }