













































































































  import Vue, { PropType } from 'vue';
  import { mixin as timeMixin } from '@/lib/time';
  import groupMixin from '@/mixins/group-mixin';
  import Icon from '@/components/Icon/Icon.vue';
  import GenerateKeyModal from '@/components/GenerateKeyModal/GenerateKeyModal.vue';
  import ImportKeysModal from '@/components/ImportKeysModal/ImportKeysModal.vue';
  import { SETTINGS_PGP_KEYRING_KEY } from '@/router/named-routes';

  export default Vue.extend({
    name: 'KeyList',
    components: { Icon, GenerateKeyModal, ImportKeysModal },
    mixins: [timeMixin, groupMixin],
    props: {
      keys: {
        type: Array as PropType<PGPKey[]>,
        required: true,
      },
      dateFormat: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        KEY_GROUP_IDS: {
          YOUR: 'your',
          PUBLIC: 'public',
          INACTIVE: 'inactive',
        },
      };
    },
    computed: {
      privateKeys(): PGPKey[] {
        return this.keys
          .filter((key) => this.keyActive(key) && key.has_private_key)
          .sort(this.sortByDisplayName);
      },
      publicKeys(): PGPKey[] {
        return this.keys
          .filter((key) => this.keyActive(key) && !key.has_private_key)
          .sort(this.sortByDisplayName);
      },
      inactiveKeys(): PGPKey[] {
        return this.keys
          .filter((key) => !this.keyActive(key))
          .sort(this.sortByDisplayName);
      },
      groupedKeys(): {
        id: string;
        title: string;
        emptyStateText?: string;
        keys: PGPKey[];
      }[] {
        return [
          {
            id: this.KEY_GROUP_IDS.YOUR,
            title: this.$gettext('Your key'),
            emptyStateText: this.$gettext(
              'You don’t have an active PGP key. Generate a new PGP key to enable encryption and message signing.'
            ),
            keys: this.privateKeys,
          },
          {
            id: this.KEY_GROUP_IDS.PUBLIC,
            title: this.$gettext('Public keys'),
            emptyStateText: this.$gettext(
              'You don’t have any active public keys of others. Import someone’s public key to send them encrypted messages and verify their signatures.'
            ),
            keys: this.publicKeys,
          },
          {
            id: this.KEY_GROUP_IDS.INACTIVE,
            title: this.$gettext('Inactive keys'),
            keys: this.inactiveKeys,
          },
        ];
      },
    },
    methods: {
      keyActive(key: PGPKey): boolean {
        return !key.expired && !key.disabled && !key.revoked;
      },
      keyStatus(key: PGPKey): string {
        if (key.expired) return this.$gettext('Expired');
        if (key.disabled) return this.$gettext('Disabled');
        if (key.revoked) return this.$gettext('Revoked');
        return this.expiryDate(key.expiry_date);
      },
      displayName(key: PGPKey): string {
        return (
          (key.user_ids && key.user_ids[0].display_name) ||
          this.$gettext('No name')
        );
      },
      emailAddress(key: PGPKey): string {
        return (key.user_ids && key.user_ids[0].address) || '-';
      },
      expiryDate(expDate: string | null): string {
        return expDate
          ? (this as any).formatDate(expDate, this.dateFormat)
          : 'No Expiration';
      },
      keyRoute(fingerprint: string): {
        name: string;
        params: { fingerprint: string };
      } {
        return { name: SETTINGS_PGP_KEYRING_KEY, params: { fingerprint } };
      },
      sortByDisplayName(a: PGPKey, b: PGPKey): number {
        const aDisplayName = this.displayName(a).toLowerCase();
        const bDisplayName = this.displayName(b).toLowerCase();
        return aDisplayName.localeCompare(bDisplayName);
      },
    },
  });
