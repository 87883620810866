<template>
  <Modal ref="modal" v-bind="$attrs">
    <template v-slot:toggle="modal">
      <slot name="DeleteButton" :toggle="modal.toggle" />
    </template>
    <template v-slot:header>
      {{ headerText || okButtonText + '?' }}
    </template>
    <template v-slot:content="modal">
      <p v-if="warningText" v-text="warningText" />
      <p v-else v-translate>It is not possible to undo this action.</p>
      <ModalConfirmActions
        is-dangerous
        @cancelClicked="modal.toggle"
        @okClicked="$emit('delete')"
        :ok-text="okButtonText"
      />
    </template>
  </Modal>
</template>

<script>
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions';
  import Modal from '@/components/Modal';

  export default {
    name: 'DeleteConfirmationModal',
    components: {
      Modal,
      ModalConfirmActions,
    },
    props: {
      warningText: {
        type: String,
        required: false,
        default: '',
      },
      headerText: {
        type: String,
        required: false,
        default: '',
      },
      okButtonText: {
        type: String,
        required: true,
        default: '',
      },
    },
    methods: {
      toggleModal() {
        this.$refs.modal.toggleModal();
      },
    },
  };
</script>
