


















































  import Vue, { PropType } from 'vue';
  import GroupSubscriptionDeleteAccountModal from '@/components/GroupSubscription/GroupSubscriptionDeleteAccountModal.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionAccount',
    components: { GroupSubscriptionDeleteAccountModal },
    data() {
      return {
        isDeleteAccountModalOpen: false,
        selectedAccount: {} as GroupSubscription,
      };
    },
    methods: {
      getAccountToDelete(account: GroupSubscription) {
        this.selectedAccount = account;
        this.isDeleteAccountModalOpen = true;
      },
    },
    props: {
      accountTitle: {
        type: String,
        default: '',
      },
      accounts: {
        type: Array as PropType<GroupSubscription[]>,
        default: () => [],
      },
      isAbleToDeleteAccount: {
        type: Boolean,
        default: false,
      },
    },
  });
