









  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView.vue';
  import KeyList from '@/components/KeyList/KeyList.vue';
  import SkeletonList from '@/components/SkeletonList/SkeletonList.vue';
  import { getDateFormat } from '@/lib/dateTimeFormats';
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'PGPKeyring',
    components: {
      KeyList,
      SkeletonList,
      ErrorView,
    },
    data() {
      return {
        state: {
          loadingKeys: true,
          loadingKeysError: null,
        },
      };
    },
    computed: {
      ...mapGetters(['keys']),
      ...mapGetters('authentication', ['user']),
      newKeyPairText() {
        return this.$gettext('New key pair');
      },
      importKeyText() {
        return this.$gettext('Import key');
      },
      optionsText() {
        return this.$gettext('Options');
      },
      dateFormat() {
        const preference =
          this.user &&
          this.user.preferences &&
          this.user.preferences.data_format;

        return getDateFormat(preference);
      },
    },
    watch: {
      $route() {
        (this as any).updateKeys();
      },
    },
    created() {
      (this as any).updateKeys();
    },
    methods: {
      ...mapActions(['loadKeys']),
      async updateKeys(this: any): Promise<void> {
        try {
          await this.loadKeys({
            query: this.$store.state.search.activeSearchQuery,
          });
          this.state.loadingKeys = false;
        } catch (error) {
          this.state.loadingKeys = false;
          this.state.loadingKeysError = ErrorType.LoadingKeysError;
          throw error;
        }
      },
    },
  });
