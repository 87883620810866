















































  import Vue from 'vue';
  import GroupSubscriptionPlanCard, {
    PlanDetail,
  } from '@/components/GroupSubscription/GroupSubscriptionPlanCard.vue';
  import GroupSubscriptionAccountCalculator from '@/components/GroupSubscription/GroupSubscriptionAccountCalculator.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import GroupSubscriptionCreateAccountModal from '@/components/GroupSubscription/GroupSubscriptionCreateAccountModal.vue';
  import GroupSubscriptionCreateAccountNotifications from '@/components/GroupSubscription/GroupSubscriptionCreateAccountNotifications.vue';
  import { mapGetters } from 'vuex';
  import GroupSubscriptionWarning from '@/components/GroupSubscription/GroupSubscriptionWarning.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionAccountPricing',
    components: {
      GroupSubscriptionPlanCard,
      GroupSubscriptionAccountCalculator,
      Icon,
      GroupSubscriptionCreateAccountModal,
      GroupSubscriptionCreateAccountNotifications,
      GroupSubscriptionWarning,
    },
    props: {
      isModal: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        currentPlan: [] as PlanDetail[],
        availablePlans: [] as PlanDetail[],
        createGroupSubscriptionNotifications: {},
      };
    },
    computed: {
      ...mapGetters('subscription', [
        'getCurrency',
        'isAccountCreateAllowed',
        'getChargebeePlan',
      ]),
      additionalAccountsHtml(): string {
        return this.$gettext(
          "<strong>Set up additional accounts for family or team members using an extended subscription. You can create as many users as you want.</strong> The amount charged today will be prorated based on time left until your subscription renews; e.g. if you have 6 months left until your subscription renews and you're on a yearly plan, you will only pay half of the annual cost for an additional email account during this subscription period."
        );
      },
    },
    created() {
      this.initializePlan();
    },
    methods: {
      buildPlanDetail(
        id: number,
        plan: PlanTier,
        isDiscount: boolean,
        currentYearlyPrice: number
      ) {
        return {
          id: id,
          minMembers: plan.starting_unit,
          maxMembers: plan.ending_unit || -1,
          pricePerMonth:
            this.getChargebeePlan?.billing_period === 'yearly'
              ? Math.ceil(plan.price / 12)
              : plan.price,
          pricePerYear: plan.price,
          isDiscount: isDiscount,
          discountAmount: isDiscount ? currentYearlyPrice - plan.price : 0,
        };
      },
      initializePlan() {
        this.getChargebeePlan?.tiers.every((plan: PlanTier, index: number) => {
          let basePrice = this.getChargebeePlan?.tiers[0].price;
          if (
            this.getChargebeePlan?.addons &&
            this.getChargebeePlan?.addons.length
          ) {
            basePrice = basePrice + this.getChargebeePlan?.addons[0].price;
          }
          if (plan.starting_unit === 1 && plan.ending_unit === 1) {
            const addons =
              this.getChargebeePlan?.addons &&
              this.getChargebeePlan?.addons.length
                ? this.getChargebeePlan?.addons[0].price
                : 0;
            let updatedPlan = JSON.parse(JSON.stringify(plan));
            updatedPlan.price = updatedPlan.price + addons;
            this.currentPlan.push(
              this.buildPlanDetail(index, updatedPlan, false, 0)
            );
            return true;
          }
          this.availablePlans.push(
            this.buildPlanDetail(index, plan, true, basePrice)
          );
          return true;
        });
      },
    },
  });
