



































































  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import * as namedRoutes from '@/router/named-routes';
  import Icon from '@/components/Icon/Icon.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionWarning',

    components: {
      Icon,
    },

    data() {
      return {
        namedRoutes,
      };
    },

    computed: {
      ...mapGetters('subscription', {
        accountCreateNotAllowedReasons: 'accountCreateNotAllowedReasons',
      }),
    },
  });
