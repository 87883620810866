




















































































  import Vue from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';
  import ImapTokensList from '@/components/ImapTokensList/ImapTokensList.vue';
  import SkeletonList from '@/components/SkeletonList/SkeletonList.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import SupportCenterLink from '../SupportCenterLink.vue';

  enum State {
    Loading,
    Loaded,
    Error,
  }

  export default Vue.extend({
    components: {
      Icon,
      FlipSwitch,
      ImapTokensList,
      SkeletonList,
      SupportCenterLink,
    },
    data() {
      return {
        State,
        state: State.Loading,
      };
    },
    computed: {
      ...mapGetters('imapTokens', ['imapTokens']),
      ...mapState('authentication', {
        imapAccessPreference: (state: any) =>
          state.user.preferences.imap_access,
      }),
      ...mapGetters('layout', ['densityOption']),
      imapAccessText(): string {
        return this.$gettext(
          'To access StartMail from other clients using IMAP'
        );
      },
      modalTitleText(): string {
        return this.$gettext('Enable IMAP');
      },
      modalParagraphText(): string {
        return this.$gettext(
          'Before you can enable IMAP, you have to complete your domain setup to work with StartMail.'
        );
      },
      redirectedFromSetupCard(): boolean {
        return window.location.hash.includes('enable-imap');
      },
    },
    async created() {
      this.state = State.Loading;
      try {
        await this.loadImapTokens();
        this.state = State.Loaded;
      } catch (err) {
        this.state = State.Error;
      }
    },
    methods: {
      ...mapActions('authentication', ['updatePreferences']),
      ...mapActions('imapTokens', ['loadImapTokens']),
      handleChange(imap_access: boolean) {
        this.updatePreferences({ update: { imap_access } });
      },
    },
  });
