<template>
  <div class="filters-settings">
    <SkeletonList v-if="isSkeletonLoading" :item-count="4" />

    <ErrorView v-else-if="error" :error-type="error" />

    <div class="panel" v-else-if="filters.length">
      <div class="panel__section">
        <div class="panel__section-item">
          <span v-translate>
            Sort your Inbox with filters. Add filters to automatically move
            incoming emails from your Inbox into other folders.
          </span>
          <SupportCenterLink
            article="360007297477"
            class="button button--small"
          >
            <translate>Learn more</translate>
            <Icon size="small" symbol="external-link" />
          </SupportCenterLink>
        </div>
        <transition-group tag="ul" name="list">
          <li
            class="panel__section-item panel__section-item--condensed"
            v-for="(filter, index) in filters"
            :key="filter.id"
          >
            <div class="row row--separated">
              <div class="row">
                <button
                  type="button"
                  class="button button--icon-only"
                  :disabled="index === 0"
                  @click.prevent="moveFilterUp(index)"
                  aria-label="Move up"
                >
                  <Icon symbol="arrow-up" />
                </button>
                <button
                  type="button"
                  :disabled="index === filters.length - 1"
                  class="button button--icon-only"
                  @click.prevent="moveFilterDown(index)"
                  aria-label="Move down"
                >
                  <Icon symbol="arrow-down" />
                </button>
              </div>

              <div class="col">
                <p
                  v-if="isSupported(filter)"
                  v-html="filterDescription(filter)"
                />
                <template v-else>
                  <translate>Could not display filter</translate>
                </template>
              </div>

              <div class="col col--auto">
                <div class="row filters-settings__actions button-group">
                  <FilterModal
                    :filter="filter"
                    @change="updateFilter($event, index)"
                  >
                    <template v-slot:default="{ toggle }">
                      <button
                        type="button"
                        class="button button--subtle button--small"
                        @click="toggle"
                      >
                        <Icon symbol="edit" />
                        <span>{{ editText }}</span>
                      </button>
                    </template>
                  </FilterModal>

                  <DeleteConfirmationModal
                    @delete="removeFilter(index)"
                    :ok-button-text="removeFilterText"
                    :warning-text="removeWarningText"
                  >
                    <template v-slot:DeleteButton="{ toggle }">
                      <button
                        type="button"
                        class="button button--subtle button--small"
                        @click="toggle"
                      >
                        <Icon symbol="trash" />
                        <span>{{ removeText }}</span>
                      </button>
                    </template>
                  </DeleteConfirmationModal>
                </div>
              </div>
            </div>
          </li>
        </transition-group>
      </div>
    </div>

    <EmptyView v-else :image="emptyStateFiltersImage" v-test:filtersEmptyState>
      <p v-html="emptyHelpText" v-test:filtersEmptyStateText />
      <SupportCenterLink
        article="360007297477"
        class="button button--small"
        v-test:learnHowLink
      >
        <translate>Learn how to use Filters</translate>
        <Icon size="small" symbol="external-link" />
      </SupportCenterLink>
    </EmptyView>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import api from '@/api';
  import DeleteConfirmationModal from '@/components/DeleteConfirmationModal/DeleteConfirmationModal';
  import Icon from '@/components/Icon/Icon';
  import FilterModal from '@/components/FilterModal/FilterModal';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import EmptyView from '@/components/EmptyView.vue';
  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView';
  import emptyStateFiltersImage from '@/assets/img/empty-state-filters.svg';
  import SkeletonList from '@/components/SkeletonList/SkeletonList';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export default {
    name: 'FiltersSettings',
    components: {
      DeleteConfirmationModal,
      EmptyView,
      ErrorView,
      FilterModal,
      Icon,
      SkeletonList,
      SupportCenterLink,
    },
    mixins: [folderUtilsMixin],
    data() {
      return {
        entityTag: '',
        filters: [],
        filterCount: 0,
        emptyStateFiltersImage,
        isSkeletonLoading: true,
        error: null,
      };
    },
    computed: {
      ...mapGetters(['folderById']),
      editText() {
        return this.$gettext('Edit');
      },
      emptyHelpText() {
        // https://github.com/Polyconseil/vue-gettext/issues/75
        return this.$gettext(
          'Ready to sort your Inbox? Add filters to automatically move incoming emails from your Inbox into other folders. Click <strong>Add filter</strong> above to start.'
        );
      },
      removeText() {
        return this.$gettext('Remove');
      },
      removeFilterText() {
        return this.$gettext('Remove filter');
      },
      removeWarningText() {
        return this.$gettext(
          'The selected filter will be permanently deleted. It is not possible to undo this action.'
        );
      },
    },
    async created() {
      try {
        const response = await api.filters.get();
        this.entityTag = response.entity_tag;
        this.filters = response.filters.map((filter) => ({
          ...filter,
          id: `filter-${this.filterCount++}`,
        }));
        // make sure folders are loaded so they can be displayed in the filter modal
        await this.loadFolders();
        this.$root.$on('addFilter', this.addFilter);
        this.$root.$on('setFilters', this.setFilters);
      } catch (error) {
        this.error = ErrorType.LoadingFiltersError;
        throw error;
      } finally {
        this.isSkeletonLoading = false;
      }
    },
    destroyed() {
      this.$root.$off('addFilter', this.addFilter);
      this.$root.$off('setFilters', this.setFilters);
    },
    methods: {
      ...mapActions(['loadFolders', 'setToastMessage']),
      isSupported(filter) {
        const supportedHeader =
          filter.tests[0].source === 'headers' &&
          ['contains', 'does not contain', 'is', 'is not'].includes(
            filter.tests[0].match
          );
        const supportedBody =
          filter.tests[0].source === 'body' &&
          ['contains', 'does not contain'].includes(filter.tests[0].match);
        const joinedActions = filter.actions
          .map(({ action }) => action)
          .join(':');
        const supportedActions = [
          'move to',
          'move to:mark read',
          'delete',
        ].includes(joinedActions);

        return (
          filter.tests.length === 1 &&
          filter.tests[0].values.length === 1 &&
          supportedActions &&
          (supportedHeader || supportedBody)
        );
      },
      field(filter) {
        if (filter.tests[0].source === 'body') {
          return this.$gettext('Body');
        }

        if (filter.tests[0].source === 'headers') {
          return filter.tests[0].headers
            .map((header) => {
              switch (header) {
                case 'from':
                  return this.$gettext('From');
                case 'subject':
                  return this.$gettext('Subject');
                case 'to':
                  return this.$gettext('To');
                case 'cc':
                  return this.$gettext('CC');
                case 'list-id':
                  return this.$gettext('List-Id');
                default:
                  return header;
              }
            })
            .join(` ${this.$gettext('or')} `);
        }

        return '';
      },
      matchDescriptionTemplate(filter) {
        if (filter.tests.length === 1) {
          switch (filter.tests[0].match) {
            case 'contains':
              return this.$gettext(
                'If <strong>%{ field }</strong> contains <strong>%{ value }</strong>,'
              );
            case 'does not contain':
              return this.$gettext(
                'If <strong>%{ field }</strong> does not contain <strong>%{ value }</strong>,'
              );
            case 'is':
              return this.$gettext(
                'If <strong>%{ field }</strong> is <strong>%{ value }</strong>,'
              );
            case 'is not':
              return this.$gettext(
                'If <strong>%{ field }</strong> is not <strong>%{ value }</strong>,'
              );
            default:
              return this.$gettext(
                'If <strong>%{ field }</strong> %{ match } <strong>%{ value }</strong>,'
              );
          }
        } else {
          return this.$gettext(
            'The UI does not support multiple matches per rule yet.'
          );
        }
      },
      actionDescriptionTemplate(filter) {
        const joinedActions = filter.actions
          .map(({ action }) => action)
          .join(':');
        switch (joinedActions) {
          case 'move to':
            return this.$gettext('move to folder <strong>%{ target }</strong>');
          case 'delete':
            return this.$gettext('<strong>delete</strong> the message');
          case 'move to:mark read':
            return this.$gettext(
              'move to folder <strong>%{ target }</strong> and mark as read'
            );
          default:
            return this.$gettext(
              'The UI does not support action %{ joinedActions } yet.'
            );
        }
      },
      filterDescription(filter) {
        const descriptionTemplate =
          this.matchDescriptionTemplate(filter) +
          ' ' +
          this.actionDescriptionTemplate(filter);
        return this.$gettextInterpolate(descriptionTemplate, {
          field: this.field(filter),
          match: this.match(filter),
          value: this.value(filter),
          target: this.target(filter),
        });
      },
      match(filter) {
        switch (filter.tests[0].match) {
          case 'contains':
            return this.$gettext('contains');
          case 'does not contain':
            return this.$gettext('does not contain');
          case 'is':
            return this.$gettext('is');
          case 'is not':
            return this.$gettext('is not');
          default:
            return filter.tests[0].match;
        }
      },
      value(filter) {
        return filter.tests[0].values[0];
      },
      target(filter) {
        const folder = this.folderById(filter.actions[0].folder) || {};
        return this.getFolderName(folder) || '';
      },
      moveFilterUp(index) {
        const target = this.filters[index - 1];
        this.$set(this.filters, index - 1, this.filters[index]);
        this.$set(this.filters, index, target);
        this.updateFilters();
      },
      moveFilterDown(index) {
        const target = this.filters[index + 1];
        this.$set(this.filters, index + 1, this.filters[index]);
        this.$set(this.filters, index, target);
        this.updateFilters();
      },
      removeFilter(index) {
        this.$delete(this.filters, index);
        this.updateFilters();
      },
      updateFilter(filter, index) {
        this.$set(this.filters, index, filter);
        this.updateFilters();
      },
      addFilter(filter) {
        this.filters = [
          ...this.filters,
          {
            ...JSON.parse(JSON.stringify(filter)),
            id: `filter-${this.filterCount++}`,
          },
        ];
        this.updateFilters();
      },
      setFilters({ filters, entity_tag }) {
        this.filterCount = 0;
        this.filters = filters.map((filter) => ({
          ...filter,
          id: `filter-${this.filterCount++}`,
        }));
        this.entityTag = entity_tag;
      },
      updateFilters() {
        const filters = this.filters.map(({ id, ...filter }) => filter);
        api.filters
          .update({ filters, entity_tag: this.entityTag })
          .then(({ entity_tag }) => {
            this.entityTag = entity_tag;
          })
          .catch((err) => {
            this.setToastMessage({
              message: this.$gettext(
                'Unable to update filters, please refresh'
              ),
            });
            throw err;
          });
      },
    },
  };
</script>

<style src="./FiltersSettings.scss" lang="scss"></style>
