


















































































  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import InputField from '@/components/form/InputField.vue';
  import Vue, { PropType } from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'DeleteGroupSubscriptionModal',
    components: { Icon, Modal, ModalConfirmActions, InputField, PasswordField },
    props: {
      selectedAccount: {
        type: Object as PropType<AccountDetails>,
        required: true,
      },
    },
    data() {
      return {
        email: '',
        isEmailEmpty: false,
        isEmailInvalid: false,
        isEmailValidNotEqual: false,
        password: '',
        isPasswordEmpty: false,
        isPasswordInvalid: false,
        loading: false,
      };
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('subscription', ['getGroupSubscriptions']),
      onModalToggled(isOpen: boolean) {
        if (!isOpen) {
          this.initialize();
          this.$emit('closeModal', isOpen);
        }
      },
      initialize() {
        this.email = '';
        this.password = '';
        this.isEmailEmpty = false;
        this.isEmailInvalid = false;
        this.isEmailValidNotEqual = false;
        this.isPasswordEmpty = false;
        this.isPasswordInvalid = false;
      },
      closeModal() {
        (this as any).$refs.modal.toggleModal();
      },
      async postDeleteGroupSubscription() {
        try {
          this.loading = true;
          const groupSubscriptionDeletionData = {
            password: this.password,
            terminate: {
              username: this.email,
            },
          };
          await api.groupSubscriptions.deleteGroupSubscription(
            groupSubscriptionDeletionData
          );
          await this.getGroupSubscriptions();
          if (this.$refs.modal) {
            (this as any).$refs.modal.toggleModal();
          }
          this.setToastMessage({
            message: this.$gettext('Account successfully deleted'),
          });
        } catch (error: any) {
          const errorCode = getErrorCode(error);
          if (errorCode === AUTHENTICATION_FAILURE) {
            this.isPasswordInvalid = true;
            return;
          } else {
            this.setToastMessage({
              message: this.$gettext(
                'Something went wrong. Please try again later.'
              ),
            });
            throw error;
          }
        } finally {
          this.loading = false;
        }
      },
      accountDeletion() {
        this.validateEmailValue();
        this.validatePasswordValue();
        if (this.validateEmailValue() && this.validatePasswordValue()) {
          this.postDeleteGroupSubscription();
        }
      },
      validateEmailValue() {
        this.email = this.email.toLowerCase();

        if (this.email === '') {
          this.isEmailEmpty = true;
        }
        if (this.email !== '' && !this.validateEmailFormat()) {
          this.isEmailInvalid = true;
        }
        if (
          this.validateEmailFormat() &&
          this.email !== this.selectedAccount.email
        ) {
          this.isEmailValidNotEqual = true;
        }
        if (this.email === this.selectedAccount.email) {
          return true;
        }
      },
      validatePasswordValue() {
        if (this.password === '') {
          this.isPasswordEmpty = true;
          this.isPasswordInvalid = false;
          return false;
        }
        this.isPasswordInvalid = false;
        this.isPasswordEmpty = false;
        return true;
      },
      validateEmailFormat(): boolean {
        return /\S+@\S+\.\S+/.test(this.email);
      },
    },
    computed: {
      okText(): string {
        return this.$gettext('Delete account');
      },
      labelEmail(): string {
        return this.$gettext('Type the email address you want to delete');
      },
      labelPassword(): string {
        return this.$gettext('Confirm with your account password');
      },
      emailValidationMessage(): string {
        if (this.isEmailInvalid) {
          return this.$gettext('This is an invalid email address.');
        }
        if (this.isEmailValidNotEqual) {
          return this.$gettext(
            'The email address is valid but is not same as the one selected.'
          );
        }
        return this.$gettext('Email address is required.');
      },
      passwordValidationError(): string {
        if (this.isPasswordEmpty) {
          return this.$gettext('Password is required.');
        }
        return this.$gettext('Wrong password.');
      },
      getConfirmationText(): string {
        return this.$gettextInterpolate(
          this.$gettext('Are you sure you want to delete %{email}?'),
          { email: this.selectedAccount.email }
        );
      },
    },
    watch: {
      email() {
        this.isEmailEmpty = false;
        this.isEmailInvalid = false;
        this.isEmailValidNotEqual = false;
      },
      password() {
        this.isPasswordEmpty = false;
        this.isPasswordInvalid = false;
      },
    },
  });
