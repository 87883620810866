



















































































































































































































  import api from '@/api';
  import { AxiosError } from 'axios';
  import UpdateRecoveryEmailModal from '@/components/UpdateRecoveryEmailModal/UpdateRecoveryEmailModal.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';
  import getErrorCode from '@/api/get-error-code';
  import RecoveryModal from '@/components/RecoveryModal/RecoveryModal.vue';
  import NewMailNotificationsModal from '@/components/NewMailNotificationsModal/NewMailNotificationsModal.vue';
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import FeatureToggle from '@/components/FeatureToggle/FeatureToggle.vue';
  import { FEATURE_RECOVERY } from '@/lib/featureFlags';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';

  export default Vue.extend({
    name: 'RecoverySettings',
    components: {
      UpdateRecoveryEmailModal,
      Icon,
      FeatureToggle,
      FlipSwitch,
      RecoveryModal,
      NewMailNotificationsModal,
    },
    data() {
      return {
        FEATURE_RECOVERY,
        processingSubmit: false,
        showEnableCodeMethodModal: false,
        showDisableCodeMethodModal: false,
        showDisableEmailMethodModal: false,
        showEnableEmailMethodModal: false,
        showNewMailNotificationsModal: false,
        authenticationError: null as number | null,
        recoveryCode: '',
        recoveryByCodeEnabled: false,
        recoveryByEmailEnabled: false,
        newMailNotificationsEnabled: false,
        recoveryInformation: {} as RecoveryStatusResponseData,
      };
    },
    computed: {
      ...mapGetters('authentication', ['user']),
      recoveryCodeText(): string {
        return this.$gettext('Recovery code');
      },
      closeText(): string {
        return this.$gettext('Close');
      },
      disableText(): string {
        return this.$gettext('Disable');
      },
      generateText(): string {
        return this.$gettext('Generate recovery code');
      },
      resetText(): string {
        return this.$gettext('Reset recovery code');
      },
      newMailNotificationsLabelText(): string {
        return this.$gettext(
          'Send daily notifications to recovery email address'
        );
      },
      emailAddressVerified(): boolean {
        const recoveryInformation = this.recoveryInformation;
        return recoveryInformation.recovery_address_confirmed;
      },
      recoveryEmailAddress(): string | null {
        return this.recoveryInformation.recovery_address;
      },
    },
    watch: {
      recoveryInformation: {
        handler(recoveryInformation: RecoveryStatusResponseData) {
          this.recoveryByCodeEnabled = recoveryInformation.recovery_key_enabled;

          const recoveryByEmailEnabled = Boolean(
            recoveryInformation.recovery_address
          );
          this.recoveryByEmailEnabled = recoveryByEmailEnabled;

          if (!recoveryByEmailEnabled) {
            this.newMailNotificationsEnabled = false;
          }
        },
        deep: true,
      },
    },
    created() {
      this.getRecoveryStatus();
      this.getNewMailNotificationsStatus();
    },
    methods: {
      ...mapActions(['setToastMessage']),
      getRecoveryStatus() {
        return api.recovery.getRecoveryStatus().then((recoveryInformation) => {
          const recoverySettingsInstance = this as any;
          recoverySettingsInstance.recoveryInformation = recoveryInformation;
          recoverySettingsInstance.recoveryByEmailEnabled = Boolean(
            recoveryInformation.recovery_address
          );
          recoverySettingsInstance.recoveryByCodeEnabled =
            recoveryInformation.recovery_key_enabled;
        });
      },
      getNewMailNotificationsStatus() {
        api.newMailNotifications.get().then((response) => {
          this.newMailNotificationsEnabled = response.enabled;
        });
      },
      enableRecoveryByCode(password: string) {
        this.processingSubmit = true;
        return api.recovery
          .enableRecoveryKey({ password })
          .then(({ new_recovery_key }: EnableRecoveryKeyResponseData) => {
            if (!new_recovery_key) throw Error('missing recovery code');
            this.recoveryCode = new_recovery_key;
          })
          .then(() => this.getRecoveryStatus())
          .catch((error: AxiosError) => {
            this.authenticationError = getErrorCode(error);
            if (this.authenticationError !== AUTHENTICATION_FAILURE) {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not generate a new recovery code. Please try again later.'
                ),
              });
            }
          })
          .finally(() => ((this as any).processingSubmit = false));
      },
      disableRecoveryByCode(password: string, modal: any) {
        this.processingSubmit = true;
        return api.recovery
          .disableRecoveryKey({ password })
          .then(() => this.getRecoveryStatus())
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Recovery by code disabled successfully'),
            });
            modal.toggle();
          })
          .catch((error: AxiosError) => {
            this.authenticationError = getErrorCode(error);
            if (this.authenticationError !== AUTHENTICATION_FAILURE) {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not disable recovery by code. Please try again later.'
                ),
              });
            }
          })
          .finally(() => (this.processingSubmit = false));
      },
      disableRecoveryByEmail(password: string, modal: any) {
        this.processingSubmit = true;
        return api.recovery
          .disableRecoveryAddress({ password })
          .then(() => this.getRecoveryStatus())
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Recovery by email disabled successfully'),
            });
            modal.toggle();
          })
          .catch((error: AxiosError) => {
            this.authenticationError = getErrorCode(error);
            if (this.authenticationError !== AUTHENTICATION_FAILURE) {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not disable recovery by email. Please try again later.'
                ),
              });
            }
          })
          .finally(() => (this.processingSubmit = false));
      },
      passwordChanged() {
        this.authenticationError = null;
      },
      resetState() {
        this.showEnableCodeMethodModal = false;
        this.showDisableCodeMethodModal = false;
        this.showEnableEmailMethodModal = false;
        this.showDisableEmailMethodModal = false;
        this.processingSubmit = false;
        this.authenticationError = null;
        this.recoveryCode = '';
      },
      updateRecoveryByCode() {
        if (this.recoveryByCodeEnabled) {
          this.showDisableCodeMethodModal = true;
        } else {
          this.showEnableCodeMethodModal = true;
        }
      },
      updateRecoveryByEmail() {
        if (this.recoveryByEmailEnabled) {
          this.showDisableEmailMethodModal = true;
        } else {
          this.showEnableEmailMethodModal = true;
        }
      },
      async toggleNotificationsEmails() {
        if (
          (!this.recoveryByEmailEnabled || !this.emailAddressVerified) &&
          !this.newMailNotificationsEnabled
        ) {
          this.showNewMailNotificationsModal = true;
          return;
        }

        try {
          await api.newMailNotifications.set(!this.newMailNotificationsEnabled);
          this.newMailNotificationsEnabled = !this.newMailNotificationsEnabled;
        } catch (error: any) {
          this.setToastMessage({
            message: this.$gettext(
              'Sorry, unable to update notifications setting'
            ),
          });

          throw error;
        }
      },
    },
  });
