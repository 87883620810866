



























































































































































  import api from '@/api';
  import {
    AUTHENTICATION_FAILURE,
    CHARGEBEE_PAYMENT_FAILED,
  } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import InputField from '@/components/form/InputField.vue';
  import SelectInput from '@/components/form/SelectInput.vue';
  import debounce from '@/lib/debounce';
  import formatCurrency from '@/lib/formatCurrency';
  import { passwordGenerator } from '@/lib/passwordGenerator';
  import * as namedRoutes from '@/router/named-routes';
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import SupportCenterLink from '../SupportCenterLink.vue';

  interface AccountDetails {
    displayName: string;
    emailAddressLocalPart: string;
    password: string;
  }

  interface OrderSummary {
    id: number;
    name: string;
    price: number;
    isDiscount: boolean;
  }

  const emptyAccountDetailsBackup = {
    displayName: '',
    emailAddressLocalPart: '',
    selectedDomain: '',
  };

  export default Vue.extend({
    name: 'GroupSubscriptionCreateAccountModal',
    components: {
      Modal,
      ModalConfirmActions,
      InputField,
      PasswordField,
      SelectInput,
      Icon,
      SupportCenterLink,
    },
    data() {
      return {
        namedRoutes,
        accountDetails: {
          displayName: '',
          emailAddressLocalPart: '',
          password: '',
        } as AccountDetails,
        accountDetailsBackup: emptyAccountDetailsBackup,
        selectedDomain: '',
        confirmPassword: '',
        formValidation: false,
        usernameUnavailable: false,
        usernameInvalid: false,
        orderSummary: [] as OrderSummary[],
        totalPrice: 0,
        activeStep: 1,
        userPassword: '',
        validatePassword: false,
        passwordErrorMessage: '',
        hasCreatePaymentFailedError: false,
      };
    },
    computed: {
      ...mapGetters('subscription', ['getCurrency']),
      verifiedDomains(): Domain[] {
        return this.$store.getters['authentication/verifiedDomains'];
      },
      domainOptions(): HTMLSelectOption[] {
        const domains = this.verifiedDomains.map((d) => d.domain_name);
        if (!domains.includes(this.$store.state.signup.domain)) {
          domains.push(this.$store.state.signup.domain);
        }
        return domains.sort().map((domainName) => ({
          value: `@${domainName}`,
          name: `@${domainName}`,
        }));
      },
      okText(): string {
        return this.$gettext('Pay & create');
      },
      displayNameText(): string {
        return this.$gettext('Display name');
      },
      emailAddressText(): string {
        return this.$gettext('Email address');
      },
      usernameValidationText(): string {
        if (this.usernameInvalid) {
          return this.$gettext('This is an invalid email address.');
        }
        if (this.usernameUnavailable) {
          return this.$gettext(
            'This StartMail email address is not available.'
          );
        }
        return this.$gettext('Email address is required.');
      },
      normalizedUsername(): string {
        return (
          this.accountDetails.emailAddressLocalPart.toLowerCase() +
          this.selectedDomain
        );
      },
      passwordDescription(): string {
        return this.$gettext(
          "Please share this password with the person you're creating the account for in a secure manner (e.g. over the phone). <strong>Upon first login, the new user will be asked to change this password.</strong>"
        );
      },
      passwordLabelText(): string {
        return this.$gettext('Password');
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      formatCurrency,
      initialize() {
        this.activeStep = 1;
        this.accountDetails = {
          displayName: '',
          emailAddressLocalPart: '',
          password: passwordGenerator(),
        };
        this.userPassword = '';
        this.validatePassword = false;
        this.formValidation = false;
        this.hasCreatePaymentFailedError = false;
        this.selectedDomain = this.domainOptions[0].value;
        this.getOrderSummaryDetail();
      },
      async getOrderSummaryDetail() {
        try {
          const orderSummary =
            await api.groupSubscriptions.getChargebeeProratedPricing();
          this.orderSummary = [
            {
              id: 0,
              name: this.$gettext('Additional account:'),
              price: orderSummary.base_price,
              isDiscount: false,
            },
            {
              id: 1,
              name: this.$gettext('Partial subscription period discount:'),
              price: orderSummary.discount_prorating,
              isDiscount: true,
            },
          ];
          this.totalPrice = orderSummary.total_prorated;
        } catch (e) {
          return;
        }
      },
      debouncedCheckAddressAvailability: debounce(function (this: any) {
        return this.checkAddressAvailability();
      }, 300),
      updateEmailAddress(event: string) {
        this.accountDetails.emailAddressLocalPart = event;
        this.debouncedCheckAddressAvailability();
      },
      checkAddressAvailability() {
        if (!this.accountDetails.emailAddressLocalPart) {
          return;
        }
        const requestedEmail = this.normalizedUsername;
        return api.authentication
          .availableAddresses({
            requestedEmail,
          })
          .then(() => {
            this.usernameUnavailable = false;
            this.usernameInvalid = false;
          })
          .catch((err) => {
            this.usernameUnavailable = err.response?.status === 404;
            this.usernameInvalid = err.response?.status === 400;
          });
      },
      setAccountDetails() {
        this.formValidation = true;
        if (
          !this.usernameInvalid &&
          !this.usernameUnavailable &&
          this.accountDetails.emailAddressLocalPart.length
        ) {
          this.activeStep = 2;
        }
      },
      async createAccount() {
        try {
          if (!this.userPassword) {
            this.validatePassword = true;
            this.passwordErrorMessage = this.$gettext('Password is required.');
            return;
          } else {
            // Used to backup filled data from the user if any error occurs when creating the account
            this.accountDetailsBackup = {
              displayName: this.accountDetails.displayName,
              emailAddressLocalPart: this.accountDetails.emailAddressLocalPart,
              selectedDomain: this.selectedDomain,
            };
            this.toggleModal();
            this.$emit('createGroupSubscriptionNotifications', {
              activeStep: 3,
              isOpen: true,
            });
            await api.groupSubscriptions.createAccount({
              password: this.userPassword,
              signup: {
                display_name: this.accountDetails.displayName,
                requested_email:
                  this.accountDetails.emailAddressLocalPart +
                  this.selectedDomain,
                password: this.accountDetails.password,
              },
            });
            api.uiEvents.create({
              event_type: 'settings_group_subscription_created_email_account',
            });
            this.$emit('createGroupSubscriptionNotifications', {
              activeStep: 4,
              isOpen: true,
            });
            this.accountDetailsBackup = emptyAccountDetailsBackup;
          }
        } catch (error: any) {
          this.$emit('createGroupSubscriptionNotifications', {
            isOpen: false,
          });
          this.toggleModal();
          this.accountDetails.displayName =
            this.accountDetailsBackup.displayName;
          this.accountDetails.emailAddressLocalPart =
            this.accountDetailsBackup.emailAddressLocalPart;
          this.selectedDomain = this.accountDetailsBackup.selectedDomain;
          const errorCode = getErrorCode(error);
          if (errorCode === AUTHENTICATION_FAILURE) {
            this.activeStep = 2;
            this.validatePassword = true;
            this.passwordErrorMessage = this.$gettext('Wrong password.');
            return;
          }
          if (errorCode === CHARGEBEE_PAYMENT_FAILED) {
            this.activeStep = 1;
            this.hasCreatePaymentFailedError = true;
            return;
          }
          this.activeStep = 1;
          this.setToastMessage({
            message: this.$gettext(
              'Something went wrong. Please try again later.'
            ),
          });
          throw error;
        }
      },
      onModalToggled(isOpen: boolean) {
        if (isOpen) {
          this.initialize();
          api.uiEvents.create({
            event_type:
              'settings_group_subscription_clicked_create_email_account',
          });
        }
      },
      async copyInitialPassword() {
        await navigator.clipboard.writeText(this.accountDetails.password);
        this.setToastMessage({ message: this.$gettext('Password copied') });
      },
      toggleModal() {
        (this as any).$refs.groupSubscriptionCreationModal.toggleModal();
      },
    },
    watch: {
      userPassword(newPassword, oldPassword) {
        if (newPassword !== oldPassword && newPassword) {
          this.passwordErrorMessage = '';
        }
      },
    },
  });
