<template>
  <div class="signature-management">
    <RichTextEditor
      v-model="signature"
      class="signature-management__editor"
      :drag-and-drop-enabled="false"
    />
    <LoadingButton
      class="button--accent"
      :loading="isBusy.savingSignature"
      @click="saveSignature"
      v-test:saveSignature
    >
      <translate>Save signature</translate>
    </LoadingButton>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapState } from 'vuex';
  import asyncActionMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import Icon from '@/components/Icon/Icon';
  import LoadingButton from '@/components/LoadingButton';
  import Spinner from '@/components/Spinner/Spinner';

  const RichTextEditor = () => ({
    component: import(
      /* webpackChunkName: "rich-text-editor" */ '@/components/RichTextEditor/RichTextEditor'
    ),
    loading: Vue.component('rich-text-editor-loading', {
      components: { Spinner },
      render(h) {
        return h('div', [
          h('div', { class: ['panel', 'panel--inverse'] }, [
            h('div', { class: ['row', 'row--separated'] }, [
              h('span', { class: ['col', 'col--auto'] }, [h('Spinner')]),
              h('span', { class: ['col'] }, this.$gettext('Loading editor')),
            ]),
          ]),
        ]);
      },
    }),
    error: Vue.component('rich-text-editor-error', {
      components: { Icon },
      render(h) {
        return h('div', [
          h('p', { class: ['panel', 'panel--inverse'] }, [
            h('div', { class: ['row', 'row--separated'] }, [
              h('span', { class: ['col', 'col--auto'] }, [
                h('Icon', {
                  class: ['danger'],
                  props: { symbol: 'error' },
                }),
              ]),
              h(
                'span',
                { class: ['col'] },
                this.$gettext(
                  'The editor failed to load. Please reload to try again!'
                )
              ),
            ]),
          ]),
          h(
            'button',
            {
              attrs: {
                type: 'button',
              },
              class: ['button', 'button--primary'],
              on: {
                click: () => window.location.reload(true),
              },
            },
            this.$gettext('Reload')
          ),
        ]);
      },
    }),
    timeout: 10000,
  });

  export default {
    name: 'SignatureManagement',
    components: {
      LoadingButton,
      RichTextEditor,
    },
    mixins: [asyncActionMixin],
    data() {
      return {
        signature: '',
      };
    },
    computed: {
      ...mapState({
        preferences: (state) => state.authentication.user.preferences,
      }),
    },
    created() {
      this.signature = this.preferences.signature || this.signature;
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['updatePreferences']),
      saveSignature: asyncAction('savingSignature', function () {
        this.setToastMessage({
          message: this.$gettext('Your signature has been changed.'),
        });
        return this.updatePreferences({
          update: {
            signature: this.signature,
          },
        });
      }),
    },
  };
</script>

<style src="./SignatureManagement.scss" lang="scss"></style>
