























































































































  import api from '@/api';
  import { CHARGEBEE_NOT_LINKED } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import DeleteAccount from '@/components/DeleteAccount/DeleteAccount.vue';
  import DownloadInvoices from '@/components/DownloadInvoices.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import InvoiceDetails from '@/components/InvoiceDetails.vue';
  import ManagePaymentSources from '@/components/ManagePaymentSources.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import SubscriptionCancellation from '@/components/SubscriptionCancellation.vue';
  import TransferOwnership from '@/components/TransferOwnership/TransferOwnership.vue';
  import { getDateFormat } from '@/lib/dateTimeFormats';
  import formatCurrency from '@/lib/formatCurrency';
  import { mixin as timeMixin } from '@/lib/time';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import PlanChooser from './PlanChooser.vue';
  import TrialStatus from './TrialStatus.vue';

  export default Vue.extend({
    name: 'Subscription',
    components: {
      DeleteAccount,
      DownloadInvoices,
      Icon,
      InvoiceDetails,
      ManagePaymentSources,
      Modal,
      ModalConfirmActions,
      PlanChooser,
      Spinner,
      SubscriptionCancellation,
      TransferOwnership,
      TrialStatus,
    },
    mixins: [timeMixin],
    data() {
      return {
        loading: true,
        loadingError: false,
        next_invoice_estimate: undefined as
          | ChargebeeDetails['next_invoice_estimate']
          | undefined,
        subscription: undefined as ChargebeeDetails['subscription'] | undefined,
        balances: undefined as ChargebeeDetails['balances'] | undefined,
      };
    },
    created() {
      this.refresh();
    },
    computed: {
      ...mapGetters('authentication', ['hasSubscription', 'user']),
      ...mapGetters('subscription', ['hasMultipleAccounts']),
      balanceHtmlItems(): string[] {
        return (this.balances ?? []).map((balance) =>
          this.$gettextInterpolate(
            this.$gettext(
              'Available credit: <strong>%{ credit }</strong> (will automatically be used for future charges)'
            ),
            {
              credit: this.formatCurrency(
                balance.promotional_credits + balance.refundable_credits,
                balance.currency_code
              ),
            }
          )
        );
      },
      cancellationDate(): string | undefined {
        return this.subscription?.cancelled_at
          ? (this as any).formatDate(
              this.subscription.cancelled_at,
              getDateFormat(this.user.preferences.date_format)
            )
          : undefined;
      },
      cancellationDateHtml(): string {
        return this.$gettextInterpolate(
          this.$gettext(
            'Your subscription is scheduled for cancellation on <strong>%{ date }</strong>.'
          ),
          { date: this.cancellationDate }
        );
      },
      currentPlanHtml(): string {
        let message =
          this.plan === 'legacy'
            ? this.$gettext(
                'You are on a <strong>legacy</strong> plan, billed <strong>%{ period }</strong>.'
              )
            : this.$gettext(
                'You are on the <strong>%{ plan }</strong> plan, billed <strong>%{ period }</strong>.'
              );
        if (!this.cancellationDate) {
          message += ' ';
          message += this.$gettext(
            'The next charge is expected on <strong>%{ renewalDate }</strong> in the amount of <strong>%{ renewalAmount }</strong>.'
          );
        }
        return this.$gettextInterpolate(message, {
          plan: this.plan,
          period: this.period,
          renewalDate: this.next_invoice_estimate
            ? this.formattedDate(this.next_invoice_estimate.date)
            : undefined,
          renewalAmount: this.next_invoice_estimate
            ? this.formatCurrency(
                this.next_invoice_estimate.total,
                this.next_invoice_estimate.currency_code
              )
            : undefined,
        });
      },
      expiryDateHtml(): string {
        return this.$gettextInterpolate(
          this.$gettext('Account expiry date: <strong>%{ date }</strong>'),
          {
            date: (this as any).formatDate(
              this.user.account_active_end,
              getDateFormat(this.user.preferences.date_format)
            ),
          }
        );
      },
      legacyNoSubscriptionHtml(): string {
        return this.$gettext(
          'You are currently on a <strong>legacy</strong> account with no subscription set up yet. You can continue using your account until the expiry date. To keep using your account after the expiry date, set up a subscription plan below.'
        );
      },
      plan(): string {
        if (!this.subscription) {
          return 'no plan';
        }

        if (
          /^(yearly|monthly)-personal-(...)$/.test(this.subscription.plan_id)
        ) {
          return this.$gettext('Personal');
        }

        if (
          /^(yearly|monthly)-business-(...)$/.test(this.subscription.plan_id)
        ) {
          return this.$gettext('Business');
        }

        return 'legacy';
      },

      period(): string {
        if (!this.subscription) {
          return 'no subscription';
        }

        if (this.subscription.billing_period_unit == 'year') {
          return this.$gettextInterpolate(
            this.$ngettext(
              'yearly',
              'every %{ n } years',
              this.subscription.billing_period
            ),
            { n: this.subscription.billing_period }
          );
        } else if (this.subscription.billing_period_unit == 'month') {
          return this.$gettextInterpolate(
            this.$ngettext(
              'monthly',
              'every %{ n } months',
              this.subscription.billing_period
            ),
            { n: this.subscription.billing_period }
          );
        }

        return 'unsupported';
      },

      okText(): string {
        return this.$gettext('Yes');
      },

      cancelText(): string {
        return this.$gettext('No');
      },

      reactivateButtonText(): string {
        return this.$gettext('Reactivate subscription');
      },
    },

    methods: {
      ...mapActions(['setToastMessage']),
      formatCurrency,
      formattedDate(date: string) {
        const dateFormat = getDateFormat(
          this.$store.state.authentication.user.preferences.date_format
        );
        return (this as any).formatDate(date, dateFormat);
      },

      async refresh() {
        this.loading = true;
        this.loadingError = false;
        try {
          const { next_invoice_estimate, subscription, balances } =
            await api.chargebee.getDetails();
          this.next_invoice_estimate = next_invoice_estimate;
          this.subscription = subscription;
          this.balances = balances;
        } catch (err) {
          if (
            err instanceof AxiosError &&
            getErrorCode(err) === CHARGEBEE_NOT_LINKED
          ) {
            this.next_invoice_estimate =
              this.subscription =
              this.balances =
                undefined;
          } else {
            this.loadingError = true;
          }
        } finally {
          this.loading = false;
        }
      },

      async reactivateSubscription(closeModal: Function) {
        await api.chargebee.reactivateSubscription();
        this.refresh();
        closeModal();
        this.setToastMessage({
          message: this.$gettext('Your subscription has been reactivated'),
        });
      },
    },
  });
