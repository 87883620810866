<template>
  <Modal ref="modal" @modalToggled="resetFilter">
    <template v-slot:toggle="{ toggle }">
      <slot :toggle="toggle" />
    </template>
    <template v-slot:header>
      <translate v-if="addFilter"> Create filter </translate>
      <translate v-else> Edit filter </translate>
    </template>
    <template v-slot:content="{ toggle }">
      <form novalidate @submit.prevent="submitFilter">
        <SelectInput
          id="field"
          class="form-field"
          :name="fieldLabel"
          :label="fieldLabel"
          v-model="field"
          :options="fields"
          required
          :validate="validate"
        />

        <SelectInput
          id="match"
          class="form-field"
          :name="matchLabel"
          :label="matchLabel"
          v-model="match"
          :options="matches"
          required
          :validate="validate"
        />

        <InputField
          id="value"
          class="form-field filter-modal__input-field"
          :label="valueLabel"
          v-model="value"
          required
          :validate="validate"
        />

        <SelectInput
          id="action"
          class="form-field"
          :name="actionLabel"
          :label="actionLabel"
          v-model="action"
          :options="actions"
          required
          :validate="validate"
        />

        <SelectInput
          id="target"
          class="form-field form-field--indented"
          :name="targetLabel"
          :label="targetLabel"
          v-model="target"
          :options="folders"
          required
          :validate="validate"
          v-if="actionIsMove"
        />

        <ModalConfirmActions @cancelClicked="toggle" :ok-text="okText" />
      </form>
    </template>
  </Modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import InputField from '@/components/form/InputField';
  import SelectInput from '@/components/form/SelectInput';
  import Modal from '@/components/Modal';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions';
  import flattenArray from '@/lib/flattenArray';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';

  export default {
    name: 'FilterModal',
    components: {
      InputField,
      Modal,
      ModalConfirmActions,
      SelectInput,
    },
    mixins: [folderUtilsMixin],
    props: {
      addFilter: {
        type: Boolean,
        default: false,
      },
      filter: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        newFilter: {},
        validate: false,
      };
    },
    computed: {
      ...mapGetters(['folderTree']),
      fieldLabel() {
        return this.$gettext('If');
      },
      matchLabel() {
        return this.$gettext('match type');
      },
      valueLabel() {
        return this.$gettext('value');
      },
      actionLabel() {
        return this.$gettext('action');
      },
      targetLabel() {
        return this.$gettext('target folder');
      },
      okText() {
        return this.addFilter
          ? this.$gettext('Create filter')
          : this.$gettext('Save filter');
      },
      fields() {
        return [
          { name: this.$gettext('From'), value: 'from' },
          { name: this.$gettext('To'), value: 'to' },
          { name: this.$gettext('CC'), value: 'cc' },
          { name: this.$gettext('To or CC'), value: 'to:cc' },
          { name: this.$gettext('Subject'), value: 'subject' },
          { name: this.$gettext('List-Id'), value: 'list-id' },
          { name: this.$gettext('Body'), value: 'body' },
        ];
      },
      matches() {
        return this.field === 'body' ? this.matchesBody : this.matchesHeader;
      },
      matchesHeader() {
        return [
          { name: this.$gettext('contains'), value: 'contains' },
          {
            name: this.$gettext('does not contain'),
            value: 'does not contain',
          },
          { name: this.$gettext('is'), value: 'is' },
          { name: this.$gettext('is not'), value: 'is not' },
        ];
      },
      matchesBody() {
        return [
          { name: this.$gettext('contains'), value: 'contains' },
          {
            name: this.$gettext('does not contain'),
            value: 'does not contain',
          },
        ];
      },
      actions() {
        return [
          { name: this.$gettext('move to folder'), value: 'move to' },
          {
            name: this.$gettext('move to folder and mark as read'),
            value: 'move to:mark read',
          },
          { name: this.$gettext('delete'), value: 'delete' },
        ];
      },
      actionIsMove() {
        return this.newFilter.actions[0].action === 'move to';
      },
      folders() {
        return this.flattenFolderTree(this.folderTree);
      },
      field: {
        get() {
          return this.newFilter.tests[0].source == 'body'
            ? 'body'
            : this.newFilter.tests[0].headers.join(':');
        },
        set(value) {
          if (value === 'body') {
            this.newFilter.tests[0].source = 'body';
            if (!this.matchesBody.includes(this.newFilter.tests[0].match)) {
              this.newFilter.tests[0].match == 'contains';
            }
            delete this.newFilter.tests[0].headers;
          } else {
            this.newFilter.tests[0].source = 'headers';
            this.newFilter.tests[0].headers = value.split(':');
          }
        },
      },
      match: {
        get() {
          return this.newFilter.tests[0].match;
        },
        set(value) {
          this.newFilter.tests[0].match = value;
        },
      },
      value: {
        get() {
          return this.newFilter.tests[0].values[0];
        },
        set(value) {
          this.newFilter.tests[0].values = [value];
        },
      },
      action: {
        get() {
          return this.newFilter.actions.map(({ action }) => action).join(':');
        },
        set(value) {
          this.$set(
            this.newFilter,
            'actions',
            value.split(':').map((action) => ({
              action,
              ...(action === 'move to' && {
                folder: this.newFilter.actions[0].folder || 'INBOX',
              }),
            }))
          );
        },
      },
      target: {
        // this silently assumes that the first action is a move!
        get() {
          return this.newFilter.actions[0].folder;
        },
        set(value) {
          this.newFilter.actions[0].folder = value;
        },
      },
    },
    methods: {
      flattenFolderTree(folders, displayPath) {
        return flattenArray(
          folders.map((folder) => {
            const newDisplayPath = displayPath
              ? displayPath + ' / ' + this.getFolderName(folder)
              : this.getFolderName(folder);
            return [
              { name: newDisplayPath, value: folder.id },
              ...this.flattenFolderTree(folder.children, newDisplayPath),
            ];
          })
        );
      },
      submitFilter(ev) {
        this.validate = true;
        const valid = ev.target.checkValidity();

        if (valid) {
          this.$emit('change', this.newFilter);
          this.$refs.modal.toggleModal();
        }
      },
      resetFilter() {
        this.validate = false;
        this.newFilter = {
          test_combinator: 'all',
          tests: [
            {
              source: 'headers',
              headers: ['from'],
              match: 'contains',
              values: [''],
            },
          ],
          actions: [
            {
              action: 'move to',
              folder: 'INBOX',
            },
          ],
          ...JSON.parse(JSON.stringify(this.filter)),
        };
      },
    },
  };
</script>

<style lang="scss">
  .filter-modal__input-field {
    max-width: 500px;
  }
</style>
