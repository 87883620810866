var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.folder.type || Boolean(_vm.folder.parent_id))?_c('div',{staticClass:"folder-actions row button-group"},[_c('Modal',{attrs:{"loading":_vm.isBusy.renaming},scopedSlots:_vm._u([{key:"toggle",fn:function(modal){return [_c('button',{directives:[{name:"test",rawName:"v-test:renameFolder",arg:"renameFolder"}],staticClass:"button button--subtle button--small button--icon-only-small-breakpoint",attrs:{"type":"button"},on:{"click":function($event){return _vm.showRenameModal(_vm.folder, modal)}}},[_c('Icon',{attrs:{"symbol":"rename"}}),_c('span',[_vm._v(_vm._s(_vm.renameText))])],1)]}},{key:"header",fn:function(){return [_c('translate',[_vm._v("Rename folder")])]},proxy:true},{key:"content",fn:function(modal){return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.renameFolderClicked(_vm.folder, modal)}}},[_c('InputField',{attrs:{"type":"text","focused":"","label":_vm.newFolderNameText,"id":"new_name","validate":_vm.newNameFilledIn,"show-custom-validity":_vm.invalidFolderName,"validation-error-message":_vm.invalidFolderNameMessage},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}}),_c('ModalConfirmActions',{attrs:{"ok-text":_vm.renameText,"disabled":_vm.invalidFolderName || !_vm.newNameFilledIn},on:{"cancelClicked":function($event){return _vm.resetModal(modal)}}})],1)]}}],null,false,2672730739)}),_c('MoveToFolderModal',{directives:[{name:"test",rawName:"v-test:moveToFolderModal",arg:"moveToFolderModal"}],attrs:{"button-text":_vm.moveText,"active-folder-id":_vm.folder.id,"busy-moving":_vm.isBusy.moving},on:{"moveToFolder":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.moveFolder.apply(void 0, [ _vm.folder.id ].concat( args ));
}},scopedSlots:_vm._u([{key:"toggle",fn:function(ref){
var modalToggle = ref.toggle;
return [_c('button',{directives:[{name:"test",rawName:"v-test:moveFolder",arg:"moveFolder"}],staticClass:"button button--subtle button--small button--icon-only-small-breakpoint",attrs:{"type":"button"},on:{"click":modalToggle}},[_c('Icon',{attrs:{"symbol":"folder-move"}}),_c('span',[_vm._v(_vm._s(_vm.moveText))])],1)]}},(!_vm.isRootFolder(_vm.folder))?{key:"additionalMoveTargets",fn:function(ref){
var modal = ref.modal;
return [_c('li',[_c('button',{staticClass:"button button--subtle button--small button--icon-only-small-breakpoint",attrs:{"type":"button"},on:{"click":function($event){return _vm.moveFolder(_vm.folder.id, '', modal)}}},[_c('Icon',{attrs:{"symbol":"root"}}),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Change to main folder")])],1)])]}}:null],null,true)}),_c('Modal',{attrs:{"loading":_vm.isBusy.removing},scopedSlots:_vm._u([{key:"toggle",fn:function(modal){return [_c('button',{directives:[{name:"test",rawName:"v-test:deleteFolder",arg:"deleteFolder"}],staticClass:"button button--subtle button--small button--icon-only-small-breakpoint",attrs:{"type":"button"},on:{"click":modal.toggle}},[_c('Icon',{attrs:{"symbol":"trash"}}),_c('span',{staticClass:"folder-actions__label"},[_vm._v(_vm._s(_vm.deleteText))])],1)]}},{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.deleteFolderText))]},proxy:true},{key:"content",fn:function(modal){return [_c('p',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"spacing-bottom"},[_vm._v(" You will lose this folder and all nested folders inside it. All the messages inside these folders will be moved to the Trash folder. ")]),_c('p',{staticClass:"user-message user-message--warning"},[_c('Icon',{staticClass:"user-message__icon",attrs:{"symbol":"warning"}}),_c('translate',[_vm._v("This action cannot be undone.")])],1),_c('ModalConfirmActions',{attrs:{"ok-text":_vm.deleteText,"is-dangerous":""},on:{"cancelClicked":modal.toggle,"okClicked":function($event){return _vm.removeFolderClicked(_vm.folder, modal)}}})]}}],null,false,2808467893)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }