







































  import Vue from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import NestedFoldersItem from '@/components/NestedFoldersItem/NestedFoldersItem.vue';
  import FolderActions from '@/components/FolderActions/FolderActions.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView.vue';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export default Vue.extend({
    components: {
      ErrorView,
      FolderActions,
      Icon,
      NestedFoldersItem,
      SupportCenterLink,
    },
    mixins: [folderUtilsMixin],
    data() {
      return {
        error: null as ErrorType | null,
        newName: '',
      };
    },
    computed: {
      ...mapGetters(['folderTree']),
    },
    created() {
      this.loadFolders()
        .then(() => {
          this.error = null;
        })
        .catch((error) => {
          this.error = ErrorType.LoadingFoldersError;
          throw error;
        });
    },
    methods: {
      ...mapActions(['loadFolders']),
    },
  });
