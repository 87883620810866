






























































































  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import RecoveryCodePrint from '@/components/RecoveryCodePrint/RecoveryCodePrint.vue';
  import formatLongNumber from '@/lib/formatLongNumber';
  import RecoveryCodePrintMixin from '@/mixins/recovery-code-print-mixin';
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'RecoveryModal',
    components: {
      Icon,
      Modal,
      ModalConfirmActions,
      PasswordField,
      RecoveryCodePrint,
    },
    mixins: [RecoveryCodePrintMixin],
    props: {
      loading: {
        type: Boolean,
        required: true,
      },
      modalIsOpen: {
        type: Boolean,
        default: false,
      },
      okText: {
        type: String,
        required: true,
      },
      recoveryCode: {
        type: String,
        required: false,
        default: '',
      },
      validationError: {
        required: true,
      },
    },
    data() {
      return {
        password: '',
        validatePassword: false,
        showPrintButton: !!window.print,
      };
    },
    computed: {
      passwordLabelText(): string {
        return this.$gettext('Your account password');
      },
      passwordValidationText(): string {
        return this.password
          ? this.$gettext('Wrong password.')
          : this.$gettext('Password is required.');
      },
      authenticationError(): boolean {
        return this.validationError === AUTHENTICATION_FAILURE;
      },
      formattedRecoveryCode(): string {
        return formatLongNumber(this.recoveryCode);
      },
    },
    watch: {
      password() {
        this.validatePassword = false;
        this.$emit('passwordChanged');
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      resetModal(modalScope: any) {
        modalScope && modalScope.toggle();
        this.password = '';
        this.validatePassword = false;
        this.$emit('modalToggled');
      },
      modalToggled(this: any, isOpen: boolean) {
        if (isOpen) {
          this.addPrintHandlers();
        } else {
          this.removePrintHandlers();
        }
        this.resetModal();
      },
      async copyRecoveryCode() {
        try {
          await navigator.clipboard.writeText(this.recoveryCode);
          this.setToastMessage({
            message: this.$gettext('Recovery code has been copied.'),
          });
        } catch (err) {
          // unable to write text to clipboard
        }
      },
      printRecoveryCode() {
        window.print();
      },
      onFormSubmit(this: any, event: Event, modal: any) {
        this.validatePassword = true;
        event.preventDefault();

        if (!(event.target as HTMLFormElement).checkValidity()) {
          return false;
        }
        return this.$emit('onSubmit', this.password, modal);
      },
    },
  });
