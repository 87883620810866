import Vue from 'vue';
import { mapActions } from 'vuex';
import AppHeader from '@/components/AppHeader/AppHeader';
import AppNavigation from '@/components/AppNavigation';

const { ...props } = AppHeader.props || {};

export default Vue.component('AppHeaderWrapper', {
  components: {
    AppHeader,
    AppNavigation,
  },
  props,
  methods: {
    ...mapActions(['toggleSidebar']),
  },
  render(createElement) {
    return createElement(AppHeader, {
      on: {
        backClicked: () => this.$emit('backClicked'),
        toggleSidebar: this.toggleSidebar,
      },
      props: {
        ...this.$props,
      },
      scopedSlots: {
        ...this.$scopedSlots,
        navigation: () =>
          createElement(AppNavigation, {
            props: {
              showLabels: true,
            },
          }),
      },
    });
  },
});
