<template>
  <section class="panel">
    <h2 class="panel__title" v-test:recipientListHeader v-translate>
      Recipient list
    </h2>
    <div class="panel__section">
      <p class="panel__section-item" v-translate>
        When you send an email to a new person that isn't your contact, their
        email address gets added to this list and will appear in the
        auto-complete suggestions.
      </p>
      <ul
        class="panel__section-item panel__section-item--no-padding recipient-list-settings__recipient-list"
        v-test:recipientList
      >
        <li
          v-for="r in recentRecipients"
          :key="r.id"
          class="recipient-list-settings__recipient"
        >
          <span class="recipient-list-settings__info-wrap">
            <span>{{ r.name || '-' }}</span>
            <router-link
              :title="r.email"
              :to="{ name: MAIL_COMPOSE, query: { mailTo: r.email } }"
              >{{ r.email }}</router-link
            >
          </span>
          <button
            @click="deleteRecipient(r.id)"
            type="button"
            class="button button--subtle button--small button--icon-only-small-breakpoint"
          >
            <Icon symbol="trash" />
            <translate>Delete</translate>
          </button>
        </li>
      </ul>
      <p
        v-if="recentRecipients.length === 0"
        class="panel__section-item"
        v-translate
      >
        No recipients yet.
      </p>
      <div v-else class="panel__section-item">
        <DeleteConfirmationModal
          ref="deleteConfirmationModal"
          :header-text="deleteModalTitleText"
          :include-in-render-tree="false"
          :ok-button-text="deleteButtonText"
          :loading="deletionPending"
          @delete="deleteRecipients"
        >
          <template v-slot:DeleteButton="{ toggle }">
            <LoadingButton class="button--accent" @click="toggle">
              <Icon symbol="trash" />
              {{ deleteButtonText }}
            </LoadingButton>
          </template>
        </DeleteConfirmationModal>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import DeleteConfirmationModal from '@/components/DeleteConfirmationModal/DeleteConfirmationModal';
  import Icon from '@/components/Icon/Icon';
  import LoadingButton from '@/components/LoadingButton';
  import { MAIL_COMPOSE } from '@/router/named-routes';

  export default {
    components: { DeleteConfirmationModal, Icon, LoadingButton },
    data() {
      return {
        MAIL_COMPOSE,
        deletionPending: false,
      };
    },
    computed: {
      ...mapState({
        recentRecipients: (state) => state.settings.recentRecipients || [],
      }),
      deleteButtonText() {
        return this.$gettext('Delete recipient list');
      },
      deleteModalTitleText() {
        return this.$gettext('Delete recipient list?');
      },
    },
    async created() {
      try {
        await this.loadRecentRecipients();
      } catch {
        this.setToastMessage({
          message: this.$gettext('Could not load recent recipients'),
        });
      }
    },
    methods: {
      ...mapActions([
        'deleteRecentRecipient',
        'deleteRecentRecipients',
        'loadRecentRecipients',
        'setToastMessage',
      ]),
      async deleteRecipient(recipientId) {
        await this.deleteRecentRecipient(recipientId);
        this.setToastMessage({
          message: this.$gettext('Email address deleted'),
        });
      },
      async deleteRecipients() {
        this.deletionPending = true;
        try {
          await this.deleteRecentRecipients();
        } finally {
          this.deletionPending = false;
        }
        this.$refs.deleteConfirmationModal?.toggleModal();
        this.setToastMessage({
          message: this.$gettext('Recipient list deleted'),
        });
      },
    },
  };
</script>

<style lang="scss">
  $recipient-item-height: 41px;

  .recipient-list-settings__recipient-list {
    display: flex;
    flex-direction: column;

    // Display maximum of 9.5 recipients at once, otherwise scroll
    max-height: calc(9.5 * #{$recipient-item-height});
    overflow-y: auto;
  }

  .recipient-list-settings__recipient {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: $spacing-xxs $spacing-default;
  }

  .recipient-list-settings__info-wrap {
    display: flex;
    line-height: 1rem;
    width: 75%;

    @media screen and (max-width: $sidebar-collapsing-breakpoint) {
      flex-basis: 80%;
      flex-direction: column;
    }

    a,
    span {
      display: inline-block;
      padding: $spacing-xxs $spacing-xxs $spacing-xxs 0;

      @include truncate;

      @media screen and (min-width: $sidebar-collapsing-breakpoint) {
        width: 50%;
      }
    }
  }
</style>
