<template>
  <div class="default-text-settings">
    <div class="text-settings">
      <div class="action-bar__row-left panel__section-item">
        <div class="action-bar__group">
          <ColorPicker
            @setTextColour="setTextColour"
            :text-colour="textColour"
            v-test:textColour
          />
          <FontSettings
            :font="font"
            :font-size="fontSize"
            @setFont="setFont"
            @setFontSize="setFontSize"
            v-test:fontSettings
          />
        </div>
      </div>
      <div class="text-settings__display-text" v-test:displayTextSettings>
        <span
          :class="`text-settings__display-text--${fontClassSuffix}
          text-settings__display-text--${fontSizeClassSuffix}
          text-settings__display-text--${colourName}`"
        >
          <translate>
            This will be your new default font, size, and color to compose
            messages.</translate
          >
        </span>
      </div>
    </div>
    <LoadingButton
      class="button--accent save-button"
      :loading="isBusy.savingTextSettings"
      @click="saveEditorPreferences"
      v-test:saveDefaultStyle
    >
      <translate>Save default style</translate>
    </LoadingButton>
  </div>
</template>

<script>
  import FontSettings, {
    fontFamilies,
  } from '@/components/FontSettings/FontSettings';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { mapState, mapActions } from 'vuex';
  import LoadingButton from '@/components/LoadingButton';
  import ColorPicker from '@/components/ColourPicker/ColourPicker';
  import { TEXT_COLOURS } from '@/components/ColourPicker/ColourPicker';
  import getKeyByValue from '@/lib/getKeyByValue';

  export default {
    name: 'DefaultTextSettings',
    components: {
      ColorPicker,
      FontSettings,
      LoadingButton,
    },
    mixins: [asyncActionsMixin],
    data() {
      return {
        fontFamilies: fontFamilies,
        fontSizeClassSuffixes: new Map([
          ['small', 'small'],
          ['normal', 'medium'],
          ['large', 'large'],
          ['huge', 'x-large'],
        ]),
        font: 'arial, helvetica',
        fontClassSuffix: '',
        fontSize: 'medium',
        fontSizeClassSuffix: '',
        textColour: 'rgb(59, 67, 92)',
        colourName: '',
      };
    },
    computed: {
      ...mapState({
        editorFont: (state) =>
          state.authentication.user.preferences.editor.default_font_family,
        editorFontSize: (state) =>
          state.authentication.user.preferences.editor.default_font_size,
        editorFontColour: (state) =>
          state.authentication.user.preferences.editor.default_font_color,
      }),
    },
    created() {
      this.font = this.editorFont || this.font;
      this.fontClassSuffix = getKeyByValue(this.fontFamilies, this.font);
      this.fontSize = this.editorFontSize || this.fontSize;
      this.fontSizeClassSuffix = getKeyByValue(
        this.fontSizeClassSuffixes,
        this.fontSize
      ).toLowerCase();
      this.textColour = this.editorFontColour || this.textColour;
      this.colourName = getKeyByValue(TEXT_COLOURS, this.textColour)
        .replace(/ /g, '-')
        .toLowerCase();
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['updatePreferences']),
      setFont(font) {
        this.font = font;
        this.fontClassSuffix = getKeyByValue(this.fontFamilies, font)
          .replace(/ /g, '-')
          .toLowerCase();
      },
      setFontSize(fontSize) {
        this.fontSize = fontSize;
        this.fontSizeClassSuffix = getKeyByValue(
          this.fontSizeClassSuffixes,
          fontSize
        );
      },
      setTextColour(colourRgbValue) {
        this.textColour = colourRgbValue;
        this.colourName = getKeyByValue(TEXT_COLOURS, colourRgbValue)
          .replace(/ /g, '-')
          .toLowerCase();
      },
      saveEditorPreferences: asyncAction('savingTextSettings', function () {
        return this.updatePreferences({
          update: {
            editor: {
              default_font_family: this.font,
              default_font_color: this.textColour,
              default_font_size: this.fontSize,
            },
          },
        })
          .then(() =>
            this.setToastMessage({
              message: this.$gettext(
                'Default text settings have been changed.'
              ),
            })
          )
          .catch(() =>
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, default text settings could not be changed.'
              ),
            })
          );
      }),
    },
  };
</script>

<style src="./DefaultTextSettings.scss" lang="scss"></style>
<style src="../FontSettings/FontSettings.scss" lang="scss"></style>
