










































































  import Vue from 'vue';
  import formatCurrency from '@/lib/formatCurrency';
  import PlanSwitchDialog from './PlanSwitchDialog.vue';
  import RibbonBanner from '@/components/RibbonBanner.vue';

  type Currency = 'USD' | 'EUR';
  type Period = 'monthly' | 'yearly';

  interface Variant {
    amount: number;
    currency: Currency;
    period: Period;
  }

  interface Plan {
    type: string;
    legacy: boolean;
    name: string;
    variants: Variant[];
    highlightedFeatures: string[];
    features: string[];
  }

  export interface PlanCard {
    plan: Plan;
    variant: Variant;
    current: boolean;
    available: boolean;
  }

  export default Vue.extend({
    components: { PlanSwitchDialog, RibbonBanner },
    props: {
      planId: { type: String, required: false, default: undefined },
    },
    data() {
      const allPlans: Plan[] = [
        {
          type: 'legacy-personal',
          legacy: true,
          highlightedFeatures: [
            this.$gettextInterpolate(
              this.$gettext('<strong>%{gb} GB</strong> storage per user'),
              { gb: 20 }
            ),
            this.$gettext('<strong>0</strong> custom domains'),
          ],
          features: [
            this.$gettext('<strong>Unlimited</strong> aliases'),
            this.$gettext(
              'Additional users at <strong>25% to 50% off</strong>'
            ),
          ],

          name: this.$pgettext('plan', 'Legacy Personal'),
          variants: [
            {
              amount: 600,
              currency: 'USD',
              period: 'monthly',
            },
            {
              amount: 525,
              currency: 'EUR',
              period: 'monthly',
            },
            {
              amount: 5995,
              currency: 'USD',
              period: 'yearly',
            },
            {
              amount: 4995,
              currency: 'EUR',
              period: 'yearly',
            },
          ],
        },
        {
          type: 'personal',
          legacy: false,
          highlightedFeatures: [
            this.$gettextInterpolate(
              this.$gettext('<strong>%{gb} GB</strong> storage per user'),
              { gb: 20 }
            ),
            this.$gettext('Add <strong>1</strong> custom domain'),
          ],
          features: [
            this.$gettext('<strong>Unlimited</strong> aliases'),
            this.$gettext('<strong>Shared</strong> aliases on your own domain'),
            this.$gettext('Additional users at <strong>25% off</strong>'),
          ],
          name: this.$pgettext('plan', 'Personal'),
          variants: [
            {
              amount: 699,
              currency: 'USD',
              period: 'monthly',
            },
            {
              amount: 699,
              currency: 'EUR',
              period: 'monthly',
            },
            {
              amount: 5988,
              currency: 'USD',
              period: 'yearly',
            },
            {
              amount: 5988,
              currency: 'EUR',
              period: 'yearly',
            },
          ],
        },
        {
          type: 'legacy-custom-domain',
          legacy: true,
          highlightedFeatures: [
            this.$gettextInterpolate(
              this.$gettext('<strong>%{gb} GB</strong> storage per user'),
              { gb: 20 }
            ),
            this.$gettext('Add <strong>unlimited</strong> custom domains'),
          ],
          features: [
            this.$gettext('<strong>Unlimited</strong> aliases'),
            this.$gettext(
              '<strong>Shared</strong> aliases on your own domains'
            ),
            this.$gettext(
              'Additional users at <strong>25% to 50% off</strong>'
            ),
          ],

          name: this.$pgettext('plan', 'Legacy Custom Domain'),
          variants: [
            {
              amount: 700,
              currency: 'USD',
              period: 'monthly',
            },
            {
              amount: 625,
              currency: 'EUR',
              period: 'monthly',
            },
            {
              amount: 6995,
              currency: 'USD',
              period: 'yearly',
            },
            {
              amount: 5995,
              currency: 'EUR',
              period: 'yearly',
            },
          ],
        },
        {
          type: 'business',
          legacy: false,
          highlightedFeatures: [
            this.$gettextInterpolate(
              this.$gettext('<strong>%{gb} GB</strong> storage per user'),
              { gb: 30 }
            ),
            this.$gettext('Add <strong>unlimited</strong> custom domains'),
          ],
          features: [
            this.$gettext('<strong>Unlimited</strong> aliases'),
            this.$gettext(
              '<strong>Shared</strong> aliases on your own domains'
            ),
            this.$gettext('Additional users at <strong>25% off</strong>'),
          ],

          name: this.$pgettext('plan', 'Business'),
          variants: [
            {
              amount: 899,
              currency: 'USD',
              period: 'monthly',
            },
            {
              amount: 899,
              currency: 'EUR',
              period: 'monthly',
            },
            {
              amount: 8388,
              currency: 'USD',
              period: 'yearly',
            },
            {
              amount: 8388,
              currency: 'EUR',
              period: 'yearly',
            },
          ],
        },
      ];
      return {
        selectedPeriod: (this.planId?.split('-')[0] ?? 'yearly') as Period,
        allPlans,
      };
    },
    computed: {
      hasDomains(): boolean {
        return this.$store.getters['authentication/hasDomains'];
      },
      currentPlanCurrency(): Currency | undefined {
        const currency = this.planId?.toUpperCase().split('-').pop();
        return currency === 'USD' || currency === 'EUR' ? currency : undefined;
      },
      currentPlanPeriod(): Period | undefined {
        const period = this.planId?.split('-')[0];
        return period === 'monthly' || period === 'yearly' ? period : undefined;
      },
      currentPlanType(): string | undefined {
        if (/^(yearly|monthly)-personal-(usd|eur)$/.test(this.planId)) {
          return 'personal';
        } else if (/^(yearly|monthly)-business-(usd|eur)$/.test(this.planId)) {
          return 'business';
        } else if (this.hasDomains) {
          return 'legacy-custom-domain';
        } else if (this.planId) {
          return 'legacy-personal';
        } else {
          return undefined;
        }
      },
      currentPlanIsLegacy(): boolean {
        return !!(
          this.currentPlanType && this.currentPlanType?.startsWith('legacy-')
        );
      },
      planCards(): PlanCard[] {
        const hasLegacyPlan =
          this.planId &&
          !/^(yearly|monthly)-(personal|business)-(usd|eur)$/.test(this.planId);
        return this.allPlans
          .filter((plan) => {
            // always show the new plans
            if (!plan.legacy) {
              return true;
            }
            // if on legacy plan, show that one as well
            if (hasLegacyPlan) {
              return (
                plan.type ===
                  (this.hasDomains
                    ? 'legacy-custom-domain'
                    : 'legacy-personal') &&
                this.currentPlanPeriod === this.selectedPeriod
              );
            }
          })
          .map((plan) => ({
            available:
              plan.type === 'business' ||
              this.$store.state.authentication.user.domains.length <= 1,
            current:
              plan.type === this.currentPlanType &&
              this.currentPlanPeriod === this.selectedPeriod,
            plan,
            variant: plan.variants.find(
              (v) =>
                v.currency === (this.currentPlanCurrency ?? 'USD') &&
                v.period === this.selectedPeriod
            ) as Variant,
          }));
      },
    },
    methods: {
      formatCurrency,
      monthlyPrice(card: PlanCard): string {
        return this.formatCurrency(
          card.variant.amount / (card.variant.period === 'yearly' ? 12 : 1),
          card.variant.currency
        );
      },
    },
  });
