


















































































































  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import InputField from '@/components/form/InputField.vue';
  import TextAreaField from '@/components/form/TextAreaField.vue';
  import { DELETED_USER } from '@/router/named-routes';
  import Vue from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default Vue.extend({
    name: 'DeleteAccount',
    components: {
      Modal,
      ModalConfirmActions,
      PasswordField,
      TextAreaField,
      InputField,
      Icon,
      Spinner,
    },
    data() {
      return {
        email: '',
        password: '',
        comment: '',
        wrongEmailAddress: false,
        missingEmailAddress: false,
        wrongPassword: false,
        missingPassword: false,
        validate: false,
        loadingDelete: true,
        loadingFailed: false,
      };
    },
    computed: {
      ...mapGetters('subscription', ['hasMultipleAccounts']),
      ...mapState({
        userEmailAddress: (state: any) => state.authentication.user.email,
      }),
      emailValidationErrorMessage(): string {
        return this.email
          ? this.wrongEmailAddressText
          : this.missingEmailAddressText;
      },
      passwordValidationErrorMessage(): string {
        return this.password
          ? this.wrongPasswordText
          : this.missingPasswordText;
      },
      deleteText(): string {
        return this.$gettext('Delete account');
      },
      textAreaLabel(): string {
        return this.$gettext('Please tell us why you are leaving (optional)');
      },
      emailLabelText(): string {
        return this.$gettext('Email address of this account');
      },
      wrongEmailAddressText(): string {
        return this.$gettext('Wrong email address.');
      },
      missingEmailAddressText(): string {
        return this.$gettext('Email address is required.');
      },
      wrongPasswordText(): string {
        return this.$gettext('Wrong password.');
      },
      missingPasswordText(): string {
        return this.$gettext('Password is required.');
      },
      passwordLabelText(): string {
        return this.$gettext('Account password of this account');
      },
      paragraphStrongText(): string {
        return this.$gettext('Are you sure you want to delete this account?');
      },
      paragraphText(): string {
        return this.$gettext(
          'The subscription will be canceled and the account, with all its data, will be permanently deleted.'
        );
      },
    },
    watch: {
      email() {
        this.wrongEmailAddress = false;
        this.missingEmailAddress = false;
      },
      password() {
        this.wrongPassword = false;
        this.missingPassword = false;
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('subscription', ['getGroupSubscriptions']),
      deleteAccount() {
        this.validate = true;
        if (this.email.trim().length === 0) {
          this.missingEmailAddress = true;
          return;
        }
        if (this.email.trim().toLowerCase() !== this.userEmailAddress) {
          this.wrongEmailAddress = true;
          return;
        }
        if (this.password.length === 0) {
          this.missingPassword = true;
          return;
        }
        return api.authentication
          .terminate({ password: this.password, comment: this.comment })
          .then(() => {
            this.$router.push({ name: DELETED_USER });
          })
          .catch((error) => {
            const loginErrorCode = getErrorCode(error);
            if (loginErrorCode === AUTHENTICATION_FAILURE) {
              this.wrongPassword = true;
              window.setTimeout(() => {
                (this as any).$refs.password.focus();
              }, 0);
            } else {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not delete your account. Please try again later.'
                ),
              });
            }
          });
      },
      onModalToggled(modalOpened: boolean) {
        if (!modalOpened) {
          this.validate = false;
          this.password = '';
          this.email = '';
          this.comment = '';
          this.wrongEmailAddress = false;
          this.missingEmailAddress = false;
          this.wrongPassword = false;
          this.missingPassword = false;
        }
      },
      closeModal() {
        (this as any).$refs.modal.toggleModal();
      },
      async getGroupSubscriptionDetails() {
        try {
          await this.getGroupSubscriptions();
        } catch {
          this.loadingFailed = true;
        } finally {
          this.loadingDelete = false;
        }
      },
    },
    created() {
      this.getGroupSubscriptionDetails();
    },
  });
