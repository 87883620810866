










  import AppHeader from '@/wrappers/AppHeaderWrapper/AppHeaderWrapper';
  import { SETTINGS_PGP_KEYRING } from '@/router/named-routes';

  export default {
    components: {
      AppHeader,
    },
    computed: {
      backToText(): string {
        return (this as any).$route.params.origin === 'message'
          ? (this as any).$gettext('Message')
          : (this as any).$gettext('PGP keyring');
      },
    },
    methods: {
      onBackClicked() {
        if ((this as any).$route.params.origin === 'message') {
          (this as any).$router.back();
        } else {
          (this as any).$router.push({ name: SETTINGS_PGP_KEYRING });
        }
      },
    },
  };
