































































  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { MAIL } from '@/router/named-routes';
  import Vue from 'vue';
  import { Location } from 'vue-router';
  import { mapState } from 'vuex';

  export default Vue.extend({
    name: 'RecoveryEmailConfirmation',
    components: {
      AuthenticationLayout,
      LoadingButton,
      PasswordField,
    },
    mixins: [asyncActionsMixin],
    data() {
      return {
        loginError: '',
        password: '',
        showConfirmation: false,
        recoveryEmail: null as string | null,
        backgroundImage: require('@/assets/img/lock-illustration.svg'),
      };
    },
    computed: {
      ...mapState('authentication', {
        userName: (state: any) => state.user.email,
      }),
      passwordText(): string {
        return this.$gettext('Your password');
      },
      inboxRoute(): Location {
        return { name: MAIL };
      },
    },
    watch: {
      password() {
        this.loginError = '';
      },
    },
    async created() {
      await this.setRecoveryStatus();
    },
    methods: {
      onConfirm: asyncAction('confirming', function (this: any, ev: Event) {
        // run client side validation before calling api
        const target = ev.target as HTMLFormElement;
        if (!target.checkValidity()) {
          return;
        }

        const confirmationToken = this.$route.params.token;
        const password = (
          target.elements.namedItem('password') as HTMLInputElement
        ).value;

        return api.recovery
          .confirmRecoveryAddress({
            confirmationToken,
            password,
          })
          .then(() => {
            this.showConfirmation = true;
          })
          .catch((error) => {
            const loginErrorCode = getErrorCode(error);
            if (loginErrorCode === AUTHENTICATION_FAILURE) {
              this.loginError = this.$gettext('Wrong password.');
              window.setTimeout(() => {
                this.$refs.password.focus();
              }, 0);
            } else {
              this.loginError = this.$gettext(
                'Sorry, we could not confirm your recovery email. Please try again later.'
              );
              throw error;
            }
          });
      }),
      async setRecoveryStatus(): Promise<void> {
        const recoveryInformation = await api.recovery.getRecoveryStatus();
        this.recoveryEmail = recoveryInformation.recovery_address;
      },
    },
  });
