







































  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import getErrorCode from '@/api/get-error-code';
  import { IMAP_FOLDER_EXISTS } from '@/api/error-codes';
  import InputField from '@/components/form/InputField.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';

  export default Vue.extend({
    name: 'FolderManagementActions',
    components: {
      InputField,
      Modal,
      ModalConfirmActions,
    },
    mixins: [asyncActionsMixin, folderUtilsMixin],
    data() {
      return {
        folderName: '',
      };
    },
    computed: {
      addText(): string {
        return this.$gettext('Add folder');
      },
      newFolderNameText(): string {
        return this.$gettext('New folder name');
      },
      invalidFolderNameMessage(): string {
        return this.$gettext(
          'Folder names may not contain the following characters: \\ / ~ " .'
        );
      },
      invalidFolderName(): boolean {
        return /[./~"\\]/.test(this.folderName);
      },
    },
    methods: {
      ...mapActions(['createFolder', 'setToastMessage']),
      addFolder: asyncAction('adding', function (this: any) {
        if (this.invalidFolderName) {
          return;
        }

        return this.createFolder({
          folder: {
            display_name: this.folderName,
            parent_id: null,
          },
        })
          .then((folder: Folder) => {
            this.setToastMessage({
              message: this.$gettextInterpolate(
                this.$gettext('%{ folderName } has been created'),
                { folderName: this.getFolderName(folder) }
              ),
            });
          })
          .catch((err: AxiosError) => {
            if (getErrorCode(err) === IMAP_FOLDER_EXISTS) {
              this.setToastMessage({
                message: this.$gettext(
                  'A folder with that name already exists'
                ),
              });
              return;
            }
            this.setToastMessage({
              message: this.$gettext('Could not add folder'),
            });
            throw err;
          });
      }),
      resetData() {
        this.folderName = '';
      },
    },
  });
