





























































































































  import api from '@/api';
  import axios from 'axios';
  import formatCurrency from '@/lib/formatCurrency';
  import Vue, { PropType } from 'vue';
  import DefaultDialog from '@/components/DefaultDialog.vue';
  import { PlanCard } from './PlanChooser.vue';
  import { mapActions } from 'vuex';
  import getErrorCode from '@/api/get-error-code';
  import { CHARGEBEE_PAYMENT_FAILED } from '@/api/error-codes';
  import LoadingButton from '@/components/LoadingButton.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';

  export default Vue.extend({
    components: {
      DefaultDialog,
      LoadingButton,
      NotificationBlock,
      PasswordField,
    },
    props: {
      card: Object as PropType<PlanCard>,
      legacy: Boolean as PropType<boolean>,
    },
    data() {
      return {
        estimate: null as ChargebeeEstimate | null,
        loading: false,
        password: '',
        wrongPassword: false,
      };
    },
    watch: {
      password() {
        this.wrongPassword = false;
      },
    },
    computed: {
      buttonText(): string {
        if (this.card.current) {
          return this.$gettext('Your current plan');
        } else if (!this.card.available) {
          return this.$gettext('Too many domains');
        } else {
          return this.$gettextInterpolate(
            this.$gettext('Switch to %{ plan }'),
            { plan: this.card.plan.name }
          );
        }
      },
      confirmButtonText(): string {
        return this.estimate?.amount_due === 0
          ? this.$gettext('Confirm')
          : this.$gettext('Confirm and pay');
      },
      wrongPasswordText(): string {
        return this.$gettext('Wrong password.');
      },
      passwordLabelText(): string {
        return this.$gettext('Confirm with your account password');
      },
      planId(): string {
        return `${this.card.variant.period}-${this.card.plan.type}-${this.card.variant.currency}`.toLowerCase();
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      formatCurrency,
      async loadEstimate(): Promise<void> {
        this.estimate = null;
        this.wrongPassword = false;
        this.password = '';
        this.estimate = await api.chargebee.estimate(this.planId);
      },
      async switchPlan(event: Event, close: Function): Promise<void> {
        this.loading = true;
        try {
          await api.chargebee.changePlan({
            password: this.password,
            plan_id: this.planId,
          });
          close();
          this.$emit('change');
        } catch (err) {
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 401) {
              this.wrongPassword = true;
              return;
            } else if (getErrorCode(err) === CHARGEBEE_PAYMENT_FAILED) {
              this.setToastMessage({
                message: this.$gettext(
                  'Payment failed. Check payment methods.'
                ),
              });
              close();
              return;
            }
          }
          this.setToastMessage({
            message: this.$gettext('Something went wrong. Try again later.'),
          });
          close();
          throw err;
        } finally {
          this.loading = false;
        }
      },
    },
  });
