


















  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import SettingsSidebar from '@/components/SettingsSidebar/SettingsSidebar.vue';
  import {
    SETTINGS_PGP_KEYRING,
    SETTINGS_PGP_KEYRING_KEY,
  } from '@/router/named-routes';

  export default Vue.extend({
    name: 'Settings',
    components: {
      SettingsSidebar,
    },
    computed: {
      isKeyManagementRoute(): boolean {
        return this.$route.name === SETTINGS_PGP_KEYRING;
      },
      isKeyView(): boolean {
        return this.$route.name === SETTINGS_PGP_KEYRING_KEY;
      },
    },
    watch: {
      $route() {
        this.updateSearchQuery();
      },
    },
    created() {
      this.updateSearchQuery();
    },
    methods: {
      ...mapActions(['setActiveSearchQuery']),
      updateSearchQuery() {
        const query = this.$route.query.query;
        if (query) {
          this.setActiveSearchQuery({ query });
        } else {
          this.setActiveSearchQuery({ query: null });
        }
      },
      onBackClicked() {
        this.$router.push({ name: SETTINGS_PGP_KEYRING });
      },
    },
  });
