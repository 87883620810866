






















































































































  import api from '@/api';
  import DefaultDialog from '@/components/DefaultDialog.vue';
  import Domain from '@/components/Domain.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';
  import InputField from '@/components/form/InputField.vue';
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    components: {
      DefaultDialog,
      Domain,
      FlipSwitch,
      Icon,
      InputField,
      PasswordField,
      Spinner,
    },
    props: {
      domainName: { type: String, required: true },
    },
    data() {
      return {
        badPassword: false,
        deleting: false,
        uid: (this as any)._uid.toString(),
        loading: false,
        catchAll: false,
        domainStatus: undefined as DomainStatus | undefined,
        domainToDelete: '',
        password: '',
      };
    },
    mounted() {
      this.update();
    },
    watch: {
      password() {
        this.badPassword = false;
      },
    },
    computed: {
      passwordLabel(): string {
        return this.$gettext('Confirm with your account password');
      },
      wrongPasswordText(): string {
        return this.$gettext('Wrong password.');
      },
      domainLabel(): string {
        return this.$gettextInterpolate(
          this.$gettext('Please type ‘%{domainName}’ to confirm'),
          { domainName: this.domainName }
        );
      },
      sortedRecordList(): DomainDNSRecord[] {
        /* Sort order for the DNS records -
                    1. TXT verification
                    2. TXT not verification
                    3. MX
                    4. CNAME (for dkim)
                    5. TXT (dmarc)
                    6. Other
                    */
        if (!this.domainStatus) return [];

        return [...this.domainStatus.dns_records].sort(
          (a: DomainDNSRecord, b: DomainDNSRecord) => {
            const sortOrder = (record: DomainDNSRecord) => {
              switch (record.rrtype) {
                case 'TXT':
                  if (record.verification) return 1;
                  else if (record.label === '_dmarc') return 5;
                  else return 2;
                case 'MX':
                  return 3;
                case 'CNAME':
                  return 4;
                default:
                  return 6;
              }
            };
            return sortOrder(a) - sortOrder(b);
          }
        );
      },
      catchAllLabel(): string {
        return this.$gettext('Catch-all');
      },
      catchAllInfoMsg(): string {
        return this.$gettext(
          'Enable a catch-all email address to receive emails sent to non-existing email accounts for your domain.'
        );
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('aliases', ['disableCatchAll', 'enableCatchAll']),
      ...mapActions('authentication', ['getAuthenticationStatus']),
      resetDeleteForm() {
        this.domainToDelete = this.password = '';
      },
      async deleteDomain(event: Event, closeDialog: Function) {
        if (this.deleting) {
          return;
        }
        this.deleting = true;
        try {
          await api.customDomains.delete({
            domainName: this.domainName,
            password: this.password,
          });
          this.getAuthenticationStatus();
          this.setToastMessage({ message: this.$gettext('Domain deleted') });
          closeDialog();
        } catch (err: any) {
          if (err?.response?.status === 401) {
            this.badPassword = true;
            this.$nextTick(() => {
              (event.target as HTMLFormElement).reportValidity();
            });
          } else if (err?.response?.status === 409) {
            closeDialog();
            this.setToastMessage({
              message: this.$gettext(
                'Cannot remove domain with active accounts'
              ),
            });
          } else {
            closeDialog();
            this.setToastMessage({
              message: this.$gettext(
                'Something went wrong. Please try again later.'
              ),
            });
            throw err;
          }
        } finally {
          this.deleting = false;
        }
      },
      async update(userTriggered = false) {
        this.loading = true;
        const wasAlreadyCompleted = this.domainStatus?.completed ?? false;
        this.catchAll = (this.$store.state.aliases.aliases as Alias[]).some(
          (alias) => alias.alias === `*@${this.domainName}`
        );
        // For user-triggered updates, ensure the check takes some
        // time to make it clear something actually happened, even
        // when the HTTP response arrives quickly.
        const minDuration = userTriggered ? 2000 : 0;
        try {
          [this.domainStatus] = await Promise.all([
            api.customDomains.get(this.domainName),
            new Promise((r) => setTimeout(r, minDuration)),
          ]);
        } finally {
          this.loading = false;

          if (this.domainStatus?.completed && !wasAlreadyCompleted) {
            // Reload user info; ‘complete your domain’ bar may
            // disappear as a result.
            this.getAuthenticationStatus();
          }
        }
      },
      async setCatchAll(value: boolean) {
        let message;
        try {
          if (value) {
            await this.enableCatchAll(this.domainName);
            message = this.$gettext('Catch-all has been enabled.');
          } else {
            await this.disableCatchAll(this.domainName);
            message = this.$gettext('Catch-all has been disabled.');
          }
        } catch {
          this.catchAll = !value;
          message = this.$gettext(
            'Something went wrong. Please try again later.'
          );
        }
        this.setToastMessage({ message });
      },
    },
  });
