<template>
  <component
    :is="tag"
    class="button button--subtle"
    :class="{
      'button--icon-only': !buttonText,
      'action-bar__button': buttonText,
      'button--icon-only-active': active,
    }"
    @click="onButtonClick($event)"
    :to="to"
    :title="title"
    :aria-label="title"
    v-bind="$attrs"
    :type="tag === 'button' ? 'button' : null"
  >
    <Icon v-if="icon" :symbol="icon" />
    <span
      v-if="buttonText"
      class="action-bar__button-text"
      v-text="buttonText"
    />
    <template v-if="dropdown">
      <Icon
        symbol="arrow-up"
        v-if="active"
        :key="active"
        class="button--arrow"
      />
      <Icon symbol="arrow-down" v-else :key="active" class="button--arrow" />
    </template>
  </component>
</template>

<script>
  import Icon from '@/components/Icon/Icon';

  export default {
    components: { Icon },
    props: {
      to: {
        type: [Object, String],
        required: false,
        default: '',
      },
      buttonText: {
        type: String,
        required: false,
        default: '',
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
      icon: {
        type: String,
        required: false,
        default: '',
      },
      dropdown: {
        type: Boolean,
        required: false,
      },
      active: {
        type: Boolean,
        required: false,
      },
    },
    computed: {
      tag() {
        return this.to ? 'router-link' : 'button';
      },
    },
    methods: {
      onButtonClick(event) {
        this.$emit('click', event);
      },
    },
  };
</script>
