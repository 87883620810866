




































































































































































  import {
    AUTHENTICATION_FAILURE,
    IMAP_TOKEN_IDENTIFIER_EXISTS,
    IMAP_TOKEN_LIMIT_REACHED,
  } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import InputField from '@/components/form/InputField.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import AppHeader from '@/wrappers/AppHeaderWrapper/AppHeaderWrapper';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions, mapState } from 'vuex';

  export default Vue.extend({
    name: 'ImapTokensHeader',
    components: {
      AppHeader,
      InputField,
      Modal,
      ModalConfirmActions,
      PasswordField,
      Icon,
    },
    mixins: [asyncActionsMixin],
    data() {
      return {
        identifier: '',
        imapToken: null,
        password: '',
        validate: false,
        authenticationError: '',
      };
    },
    computed: {
      ...mapState('authentication', {
        imapAccessPreference: (state: any) =>
          state.user.preferences.imap_access,
        user: (state: any) => state.user,
      }),
      addText(): string {
        return this.$gettext('Add device');
      },
      nameText(): string {
        return this.$gettext('Device name');
      },
      passwordText(): string {
        return this.$gettext('Confirm with your account password');
      },
    },
    methods: {
      ...mapActions('imapTokens', ['createImapToken']),
      ...mapActions(['setToastMessage']),
      addImapToken: asyncAction(
        'adding',
        function (
          this: any,
          identifier: string,
          password: string,
          toggle: Function
        ) {
          this.validate = true;
          if (!password) return;
          return this.createImapToken({ identifier, password })
            .then((imapToken: ImapToken) => {
              toggle();
              this.imapToken = imapToken;
            })
            .catch((err: AxiosError) => {
              switch (getErrorCode(err)) {
                case IMAP_TOKEN_LIMIT_REACHED:
                  toggle();
                  this.setToastMessage({
                    message: this.$gettext(
                      'You have reached the maximum number of devices.'
                    ),
                  });
                  break;
                case IMAP_TOKEN_IDENTIFIER_EXISTS:
                  this.setToastMessage({
                    message: this.$gettext(
                      'A device with the given name already exists.'
                    ),
                  });
                  break;

                case AUTHENTICATION_FAILURE:
                  this.authenticationError = this.$gettext('Wrong password.');
                  this.$nextTick(() => {
                    this.$refs.password.focus();
                  });
                  break;

                default:
                  toggle();
                  this.setToastMessage({
                    message: this.$gettext(
                      'Something went wrong. Please try again later.'
                    ),
                  });
                  throw err;
              }
            });
        }
      ),
      resetImapToken() {
        this.imapToken = null;
      },
      resetState() {
        this.identifier = '';
        this.password = '';
        this.validate = false;
        this.authenticationError = '';
      },
    },
  });
