
































  import Vue from 'vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import RadioInput from '@/components/form/RadioInput.vue';
  import InputField from '@/components/form/InputField.vue';

  interface feedbackOption {
    name: string;
    value: string;
    hasExtraInput?: boolean;
    extraInputLabel?: string;
  }

  export default Vue.extend({
    name: 'SubscriptionCancellationFeedback',

    components: {
      ModalConfirmActions,
      RadioInput,
      InputField,
    },

    data() {
      return {
        selectedFeedback: '',
        feedbackText: '',
      };
    },

    computed: {
      nextText(): string {
        return this.$gettext('Next');
      },

      cancelButtonText(): string {
        return this.$gettext('Cancel subscription');
      },

      getSubTitleText(): string {
        return this.$gettext(
          '<strong>Please tell us why you want to cancel your subscription.</strong> The subscription will expire on the day of termination, until then your account will be active.'
        );
      },

      shuffledFeedbackOptions(): feedbackOption[] {
        // Use a stable random order to avoid bias. This computed
        // property must not depend on any changing component state,
        // to avoid changing the order during a rerender, e.g. after
        // selecting an option.
        const options = [
          {
            value: 'too expensive',
            name: this.$gettext('Too expensive'),
          },
          {
            value: 'difficulties or missing functionalities',
            name: this.$gettext('Difficulties or missing functionalities'),
          },
          {
            value: 'prefer other provider',
            name: this.$gettext('Prefer other provider'),
          },
          {
            value: 'not using',
            name: this.$gettext('Not using'),
          },
        ];
        // shuffling appproach based on https://stackoverflow.com/a/46545530
        return options
          .map((item) => ({ item, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ item }) => item);
      },

      feedbackOptions(): feedbackOption[] {
        return [
          ...this.shuffledFeedbackOptions,
          {
            value: 'other',
            name: this.$gettext('Other'),
            hasExtraInput: this.selectedFeedback === 'other',
            extraInputLabel: this.$gettext('Please specify'),
          },
        ];
      },
    },

    methods: {
      toggleFeedback() {
        if (this.selectedFeedback === 'other') {
          this.selectedFeedback = `other: ${this.feedbackText}`;
        }
        this.$emit('submit', this.selectedFeedback);
      },
    },
  });
