
















  /*

  This is a modal dialog that you can stop the user from closing.

  It is teleported to the <body> so that click events bubble directly to the
  body and bypass the DOM elements where this component happens to be mounted.

  It keeps some margin from the screen edges so it still looks like a dialog on
  small screens. It does not grow beyond a set width on very large / wide
  screens to keep it readable.

  The contents of the modal are entirely up to the consumer of this component.

  You would usually not use this component directly, but go for the
  <DefaultDialog /> instead.

  */

  import Vue from 'vue';
  import Teleport from 'vue2-teleport';

  export default Vue.extend({
    components: { Teleport },
    props: {
      /*
        If set to true, cancel events are `preventDefault()`ed. This stops the
        dialog from closing on ESC key for example. This is mostly useful for
        not letting users close a dialog while awaiting the result of an API
        call.
      */
      closable: {
        type: Boolean,
        default: true,
        required: false,
      },
      startOpen: {
        type: Boolean,
        default: false,
        required: false,
      },
      narrow: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    mounted() {
      if (this.startOpen) {
        this.showModal();
      }
    },
    methods: {
      maybePrevent(event: Event) {
        if (!this.closable) {
          event.preventDefault();
        } else {
          this.$emit('cancel');
        }
      },
      showModal() {
        (this.$refs.dialog as HTMLDialogElement | undefined)?.showModal();
      },
      close() {
        (this.$refs.dialog as HTMLDialogElement | undefined)?.close();
      },
    },
  });
