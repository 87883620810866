<template>
  <div class="dropdown" @keydown.esc.exact="closeDropDown">
    <slot name="button" :toggle="toggleDropDown" :isOpen="dropdownIsOpen" />
    <div class="dropdown__content" ref="content">
      <transition name="grow" @enter="onEnter" @after-leave="afterLeave">
        <div
          class="grow-element dropdown__menu"
          v-show="dropdownIsOpen"
          v-test:dropdownMenu
          ref="menu"
        >
          <div class="grow-element__content">
            <slot
              name="content"
              :toggle="toggleDropDown"
              :isOpen="dropdownIsOpen"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Dropdown',
    data() {
      return {
        dropdownIsOpen: false,
      };
    },
    beforeDestroy() {
      document.removeEventListener('click', this.onDocumentClick);
    },
    methods: {
      toggleDropDown() {
        if (this.dropdownIsOpen) {
          this.closeDropDown();
        } else {
          this.openDropDown();
        }
      },
      openDropDown() {
        this.dropdownIsOpen = true;
        document.addEventListener('click', this.onDocumentClick);
      },
      closeDropDown() {
        this.dropdownIsOpen = false;
        document.removeEventListener('click', this.onDocumentClick);
      },
      onDocumentClick({ target }) {
        if (
          this.dropdownIsOpen &&
          this.$el !== target &&
          !this.$el.contains(target)
        ) {
          this.toggleDropDown();
        }
      },
      onEnter(el) {
        const { left, right } = el.getBoundingClientRect();
        const screenWidth = document.documentElement.clientWidth;

        if (left < 0) {
          this.$refs.content.style.transform = `translateX(calc(${Math.abs(
            left
          )}px + 2rem))`;
        } else if (right > screenWidth) {
          this.$refs.content.style.transform = `translateX(calc(${
            screenWidth - right
          }px - 2rem))`;
        }
      },
      afterLeave() {
        if (this.$refs.content) {
          this.$refs.content.style.transform = null;
        }
      },
    },
  };
</script>

<style src="./Dropdown.scss" lang="scss"></style>
