export function substituteExternalLink(innerHTML: string, href: string) {
  // Add an external hyperlink to a HTML snippet.
  //
  // This is intended to simplify translatable messages with an
  // external link, e.g. a link to a support center article.
  //
  // The input HTML should contain a single <a> tag, without any
  // attributes, e.g. ‘Oops! Check the <a>support article</a>.’
  const el = document.createElement('div');
  el.innerHTML = innerHTML;
  const link = el.getElementsByTagName('a')[0];
  if (!link) {
    throw new Error('missing anchor tag');
  }
  link.href = href;
  link.target = '_blank';
  link.relList.add('noopener', 'noreferrer');
  return el.innerHTML;
}
