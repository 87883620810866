
























































































  import Vue from 'vue';
  import { mapState, mapActions } from 'vuex';
  import api from '@/api';
  import ChangePasswordModal from '@/components/ChangePasswordModal/ChangePasswordModal.vue';
  import InputField from '@/components/form/InputField.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import RecoverySettings from '@/components/RecoverySettings/RecoverySettings.vue';

  export default Vue.extend({
    name: 'AccountDetails',
    components: {
      ChangePasswordModal,
      InputField,
      Modal,
      ModalConfirmActions,
      RecoverySettings,
    },
    mixins: [asyncActionsMixin],
    data() {
      return {
        name: '',
        editing: false,
        display_name: '',
        newEmail: '',
        changePasswordChangingPassword: false,
        changePasswordValidationErrorMessages: {},
      };
    },
    computed: {
      ...mapState({
        preferences: (state: any) => state.authentication.user.preferences,
        user: (state: any) => state.authentication.user,
      }),
      displayNameLabel(): string {
        return this.$gettext('New display name');
      },
      noDisplayNameText(): string {
        return this.$gettext('No display name');
      },
      confirmText(): string {
        return this.$gettext('Confirm');
      },
      userInputs(): string[] {
        return [this.user.display_name || '', this.user.email];
      },
      changePasswordCurrentPasswordText(): string {
        return this.$gettext('Old password');
      },
      changePasswordNewPasswordText(): string {
        return this.$gettext('New password');
      },
      changePasswordRepeatPasswordText(): string {
        return this.$gettext('Repeat password');
      },
      noRecoveryEmailText(): string {
        return this.$gettext('No recovery email');
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['updatePreferences']),
      resetModal(modalScope: any) {
        modalScope.toggle();
        this.display_name = '';
      },
      showChangeDisplayNameModal(modalScope: any) {
        this.display_name = this.preferences.display_name;
        modalScope.toggle();
      },
      changeDisplayName: asyncAction(
        'changingDisplayName',
        async function (this: any) {
          try {
            await this.updatePreferences({
              update: { display_name: this.display_name },
            });
            await this.$store.dispatch(
              'authentication/getAuthenticationStatus'
            );
            this.setToastMessage({
              message: this.$gettext('Your display name has been changed.'),
            });
          } catch {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not change your display name.'
              ),
            });
          }
        }
      ),
      changePassword({
        currentPassword,
        newPassword,
      }: {
        currentPassword: string;
        newPassword: string;
      }) {
        this.changePasswordChangingPassword = true;

        api.authentication
          .changePassword({ currentPassword, newPassword })
          .then(() => {
            (this.$refs.changePasswordModal as any).toggleModal();
            this.setToastMessage({
              message: this.$gettext('Password has been changed'),
            });
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.changePasswordValidationErrorMessages = {
                currentPassword: this.$gettext('Wrong old password.'),
              };
            } else {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not change your password'
                ),
              });
            }
          })
          .finally(() => {
            this.changePasswordChangingPassword = false;
          });
      },
      changePasswordResetValidation() {
        this.changePasswordValidationErrorMessages = {};
      },
    },
  });
