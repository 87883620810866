















































  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import InputField from '@/components/form/InputField.vue';
  import formatCurrency from '@/lib/formatCurrency';
  import RibbonBanner from '@/components/RibbonBanner.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionAccountCalculator',
    components: {
      InputField,
      RibbonBanner,
    },
    data() {
      return {
        totalAccounts: '',
        costPerMonth: 0,
        costPerYear: 0,
        discount: 0,
        isYearly: true,
        addon: { price: 0 },
      };
    },
    computed: {
      ...mapGetters('subscription', ['getChargebeePlan', 'getCurrency']),
      numberOfAccountsLabel(): string {
        return this.$gettext('Number of accounts');
      },
      billingPeriodsLabel(): string {
        return this.isYearly
          ? this.$gettext('Total yearly')
          : this.$gettext('Total monthly');
      },
      saveText(): string {
        return this.$gettextInterpolate(
          this.$pgettext('money', 'Save %{ amount }'),
          { amount: this.formatCurrency(this.discount, this.getCurrency) }
        );
      },
    },
    created() {
      this.isYearly =
        this.getChargebeePlan.billing_period === 'yearly' ? true : false;
      this.initialize();
    },
    methods: {
      formatCurrency,
      initialize() {
        this.costPerMonth = 0;
        this.costPerYear = 0;
        this.discount = 0;
        this.totalAccounts = '';
      },
      setToMax() {
        this.totalAccounts = '99';
        this.calculation();
      },
      calculation() {
        const validateCharacters = new RegExp('^[0-9]+$');
        const totalAccounts = parseInt(this.totalAccounts);
        if (
          isNaN(totalAccounts) ||
          totalAccounts === 0 ||
          !validateCharacters.test(this.totalAccounts)
        ) {
          this.initialize();
        } else if (totalAccounts > 0 && totalAccounts <= 99) {
          let totalCost = 0;
          for (let i = 0; i < this.getChargebeePlan.tiers.length; i++) {
            const tier = this.getChargebeePlan.tiers[i];
            if (totalAccounts >= tier.starting_unit) {
              const endingUnit =
                tier.ending_unit && tier.ending_unit < totalAccounts
                  ? tier.ending_unit
                  : totalAccounts;
              const accountsInTier = endingUnit - tier.starting_unit + 1;
              totalCost += accountsInTier * tier.price;
            }
          }
          for (let i = 0; i < this.getChargebeePlan.addons.length; i++) {
            this.addon = this.getChargebeePlan.addons[i];
            totalCost += this.addon.price;
          }
          if (this.getChargebeePlan.billing_period === 'yearly') {
            this.costPerYear = totalCost;
            this.costPerMonth = totalCost / 12;
          } else {
            this.costPerYear = 0;
            this.costPerMonth = totalCost;
          }
          this.discount =
            totalAccounts *
              (this.getChargebeePlan.tiers[0].price + this.addon.price) -
            totalCost;
        } else if (totalAccounts > 99) {
          this.setToMax();
        }
      },
    },
  });
