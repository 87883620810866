











































  import Vue from 'vue';
  import { mapActions, mapState } from 'vuex';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';

  export default Vue.extend({
    name: 'Encryption',
    components: {
      FlipSwitch,
    },
    computed: {
      ...mapState('authentication', {
        pgpAutoEncryptPreference: (state: any) =>
          state.user.preferences.pgp_auto_encrypt,
        pgpAutoSignPreference: (state: any) =>
          state.user.preferences.pgp_auto_sign,
        pgpAutoAttachPublicKeyPreference: (state: any) =>
          state.user.preferences.pgp_auto_attach_public_key,
      }),
      pgpAutoEncrypt: {
        get(): boolean {
          return this.pgpAutoEncryptPreference;
        },
        set(pgp_auto_encrypt) {
          this.updatePreferences({ update: { pgp_auto_encrypt } });
        },
      },
      pgpAutoEncryptLabel(): string {
        return this.$gettext('Encrypt all outgoing mail');
      },
      pgpAutoSign: {
        get(): boolean {
          return this.pgpAutoSignPreference;
        },
        set(pgp_auto_sign) {
          this.updatePreferences({ update: { pgp_auto_sign } });
        },
      },
      pgpAutoSignLabel(): string {
        return this.$gettext(
          'Always sign outgoing mail with your PGP-signature'
        );
      },
      pgpAutoAttachPublicKey: {
        get(): boolean {
          return this.pgpAutoAttachPublicKeyPreference;
        },
        set(pgp_auto_attach_public_key) {
          this.updatePreferences({ update: { pgp_auto_attach_public_key } });
        },
      },
      pgpAutoAttachPublicKeyLabel(): string {
        return this.$gettext('Always attach your public key to outgoing mail');
      },
    },
    methods: {
      ...mapActions('authentication', ['updatePreferences']),
    },
  });
