const numToOrdinalEnglish = (num: number): string => {
  let ord = 'th';
  if (num % 10 == 1 && num % 100 != 11) {
    ord = 'st';
  } else if (num % 10 == 2 && num % 100 != 12) {
    ord = 'nd';
  } else if (num % 10 == 3 && num % 100 != 13) {
    ord = 'rd';
  }
  return ord;
};

// german is only a dot at this point
const numToOrdinalGerman = (): string => {
  return '.';
};

const numToOrdinal = (num: number, lang = 'en_US'): string => {
  if (lang === 'de_DE') {
    return numToOrdinalGerman();
  }
  return `<sup>${numToOrdinalEnglish(num)}</sup>`;
};

export default numToOrdinal;
