<template>
  <header class="app-header">
    <button
      v-if="showBackButton"
      type="button"
      @click="$emit('backClicked')"
      :aria-label="backButtonText"
      v-tooltip="{ content: backButtonText, placement: 'right' }"
      class="button app-header__back-button"
    >
      <Icon symbol="previous" />
      <span class="app-header__title app-header__back-button-text">{{
        backButtonText
      }}</span>
    </button>

    <button
      v-else-if="showNavigation"
      type="button"
      class="button app-header__icon sidebar-toggle"
      @click="$emit('toggleSidebar')"
      :aria-label="toggleMenuText"
      :title="toggleMenuText"
      v-test:sidebarToggle
    >
      <Icon symbol="menu" />
    </button>

    <slot />
    <h1 v-if="$scopedSlots.title" class="app-header__title" v-test:appHeader>
      <slot name="title" />
    </h1>

    <div
      v-if="$scopedSlots.button"
      class="app-header__buttons"
      v-test:headerButtons
    >
      <slot name="button" />
    </div>
    <div
      v-if="$scopedSlots.navigation && showNavigation"
      class="app-header__app-navigation sidebar-edges"
      v-test:horizontalNavigation
    >
      <slot name="navigation" />
    </div>
  </header>
</template>

<script>
  import Icon from '@/components/Icon/Icon';
  export default {
    components: {
      Icon,
    },
    props: {
      showNavigation: {
        type: Boolean,
        default: true,
      },
      showBackButton: {
        type: Boolean,
        default: false,
      },
      backText: {
        type: String,
        default: '',
      },
    },
    computed: {
      backButtonText() {
        return this.backText
          ? this.$gettextInterpolate(
              this.$gettext('Back to %{ location }'),
              { location: this.backText },
              true
            )
          : this.$gettext('Back');
      },
      toggleMenuText() {
        return this.$gettext('Toggle menu');
      },
    },
  };
</script>

<style src="./AppHeader.scss" lang="scss"></style>
