











  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    props: {
      left: { type: Boolean as PropType<boolean>, default: false },
      right: { type: Boolean as PropType<boolean>, default: true },
    },
  });
