



























  import Vue from 'vue';
  import Modal from '@/components/Modal.vue';
  import RecoveryCodePrintMixin from '@/mixins/recovery-code-print-mixin';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';

  export default Vue.extend({
    name: 'NewMailNotificationsModal',
    components: {
      Modal,
      ModalConfirmActions,
    },
    mixins: [RecoveryCodePrintMixin],
    props: {
      modalIsOpen: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      okText() {
        return this.$gettext('OK');
      },
    },
  });
