import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      originalTitle: '',
    };
  },
  methods: {
    beforePrint() {
      this.originalTitle = document.title;
      document.title = this.$gettext('StartMail recovery');
    },
    afterPrint() {
      document.title = this.originalTitle;
    },
    addPrintHandlers() {
      window.addEventListener('beforeprint', this.beforePrint);
      window.addEventListener('afterprint', this.afterPrint);
    },
    removePrintHandlers() {
      window.removeEventListener('beforeprint', this.beforePrint);
      window.removeEventListener('afterprint', this.afterPrint);
    },
  },
});
