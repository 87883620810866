











  import Vue from 'vue';
  import AppHeader from '@/wrappers/AppHeaderWrapper/AppHeaderWrapper';

  export default Vue.extend({
    name: 'SettingsHeader',
    components: {
      AppHeader,
    },
    computed: {
      title() {
        return this.$route.meta?.headerTitle
          ? this.$route.meta.headerTitle(this)
          : this.$route.meta?.title(this);
      },
    },
  });
